import { Chip, Stack, Typography } from "@mui/material";
import React from "react";

const UserNameTag = ({name="John Doe",type="Standard"}) => {
  return (
    <Stack flexDirection={"row"} alignItems="center">
      <Typography mr={1} color="white">
        {name}
      </Typography>
      <Chip mx={1} label={type}color="success" />
    </Stack>
  );
};

export default UserNameTag;
