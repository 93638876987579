import {Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";

const CoverLine = ({ text, covered }) => {
  return (
    <Stack my={"25px"} flexDirection={"row"} justifyContent="space-between">
      <Typography fontWeight={"bold"}>{text}</Typography>
      {covered ? <CheckCircleIcon color={"success"} /> : <CancelIcon />}
    </Stack>
  );
};

export default CoverLine;
