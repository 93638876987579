import * as APIHelper from "./APIHelper";

const apiName = "mudawylAccountExchangeRESTv1";

export const listExchangeKey = async () => {
  return await APIHelper.getAuthedData(
    "/v1/priv/account/exchange/key/list",
    apiName
  );
};

export const createExchangeKey = async (body) => {
  return await APIHelper.postAuthedData(
    "/v1/priv/account/exchange/key",
    body,
    apiName
  );
};

export const updateExchangeKey = async (body, keyId) => {
  return await APIHelper.putAuthedData(
    `/v1/priv/account/exchange/key/${keyId}`,
    body,
    apiName
  );
};

export const deleteExchangeKey = async (keyId) => {
  return await APIHelper.deleteAuthedData(
    `/v1/priv/account/exchange/key/${keyId}`,
    apiName
  );
};

export const getExchangeKeyBalance = async (keyId) => {
  return await APIHelper.getAuthedData(
    `/v1/priv/account/exchange/key/${keyId}/balance`,
    apiName
  );
};

export const listExchangeKeyBalanceHistory = async (keyId, queryString) => {
  let baseUrl = `/v1/priv/account/exchange/key/${keyId}/balance/history`;
  if (queryString) {
    const { start, limit, nextToken } = queryString;
    if (start || limit || nextToken) {
      baseUrl += "?";
      if (start) {
        baseUrl += `start=${start}&`;
      }
      if (limit) {
        baseUrl += `limit=${limit}&`;
      }
      if (nextToken) {
        baseUrl += `start=${nextToken}&`;
      }
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }
  }

  return await APIHelper.getAuthedData(baseUrl, apiName);
};
