import React, { useState } from "react";
import InfoDisplay from "./InfoDisplay";
import PasswordInput from "./PassWordInput";

const PasswordEdit = () => {
  const [isEditting, setIsEditting] = useState(false);

  const toggleIsEditting = () => setIsEditting((prev) => !prev);

  return (
    <>
      {!isEditting ? (
        <InfoDisplay title="Password" value="**********" buttonText={"Change Password"} toggle={toggleIsEditting} />
      ) : <PasswordInput toggle={toggleIsEditting}/>}
    </>
  );
};

export default PasswordEdit;
