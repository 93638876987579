import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import APIFunction from "../../api";
import OrderTable from "./OrderTable";
import CoverScreenLoader from "./../CoverScreenLoader";
import moment from "moment/moment";
import { grey } from "@mui/material/colors";

const AutomatedTable = ({ currentKey }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [option, setOption] = useState("position");
  const [rows, setRows] = useState([]);

  const columnOptions = {
    position: [
      "Symbol",
      "Side",
      "Size",
      "EntryPrice",
      "Mark Price",
      "Liq Price",
      "Margin Ratio",
      "ROE ",
      "Action",
    ],
    open: [
      "Time",
      "Symbol",
      "Type",
      "Side",
      "Price",
      "Amount",
      "Filled",
      "ReduceOnly",
      "Trigger Condition",
    ],
    history: [
      "Time",
      "Symbol",
      "Type",
      "Side",
      "Average",
      "Price",
      "Executed",
      "Amount",
      "Reduce Only",
      "Trigger Condition",
    ],
  };

  useEffect(() => {
    const rowsFetcher = async () => {
      setRows([]);
      setIsLoading(true);
      try {
        if (option === "position") {
          const position = await APIFunction.getFuturesOpenPosition(currentKey);
          setRows([position]);
        } else if (option === "open") {
          const openOrders = await APIFunction.listOpenOrder(currentKey);
          setRows(openOrders);
        } else {
          const orderHistory = await APIFunction.listOrderHistory(currentKey);
          setRows(orderHistory);
        }
      } catch (e) {
        console.log(e);
      }
      setIsLoading(false);
    };
    if (currentKey) {
      rowsFetcher();
    }else{
      setIsLoading(false)
    }
  }, [option, currentKey]);

  const renderRows = () => {
    if (option === "position") {
      return rows?.map(
        ({
          symbol,
          side,
          contracts,
          entryPrice,
          markPrice,
          liquidationPrice,
          marginRatio,
          unrealizedPnl,
        }) => (
          <TableRow>
            <TableCell key={"symbol"} id={"symbol"}>
              {symbol}
            </TableCell>
            <TableCell key={"side"} id={"side"}>
              {side}
            </TableCell>
            <TableCell key={"contracts"} id={"contracts"}>
              {contracts}
            </TableCell>
            <TableCell key={"entryPrice"} id={"entryPrice"}>
              {entryPrice}
            </TableCell>
            <TableCell key={"markPrice"} id={"markPrice"}>
              {markPrice}
            </TableCell>
            <TableCell key={"liquidationPrice"} id={"liquidationPrice"}>
              {liquidationPrice}
            </TableCell>
            <TableCell key={"marginRatio"} id={"marginRatio"}>
              {marginRatio}
            </TableCell>
            <TableCell key={"unrealizedPnl"} id={"unrealizedPnl"}>
              {unrealizedPnl}
            </TableCell>
            <TableCell key={"action"} id={"action"}>
              <Button
                sx={{ color: grey[400] }}
                onClick={async () => {
                  setIsLoading(true);
                  await APIFunction.cancelFuturePosition(currentKey);
                  setRows([]);
                  setIsLoading(false);
                }}
              >
                Close
              </Button>
            </TableCell>
          </TableRow>
        )
      );
    } else if (option === "open") {
      return rows?.map(
        ({
          datetime,
          symbol,
          type,
          side,
          price,
          amount,
          filled,
          reduceOnly,
          stopPrice,
        }) => (
          <TableRow>
            <TableCell key={"dateTime"} id={"dateTime"}>
              {datetime}
            </TableCell>
            <TableCell key={"symbol"} id={"symbol"}>
              {symbol}
            </TableCell>
            <TableCell key={"type"} id={"type"}>
              {type}
            </TableCell>
            <TableCell key={"side"} id={"side"}>
              {side}
            </TableCell>
            <TableCell key={"price"} id={"price"}>
              {price}
            </TableCell>
            <TableCell key={"amount"} id={"amount"}>
              {amount}
            </TableCell>
            <TableCell key={"filled"} id={"filled"}>
              {filled}
            </TableCell>
            <TableCell key={"reduceOnly"} id={"reduceOnly"}>
              {reduceOnly}
            </TableCell>
            <TableCell key={"stopPrice"} id={"stopPrice"}>
              {stopPrice}
            </TableCell>
          </TableRow>
        )
      );
    } else {
      return rows?.map(
        ({
          id,
          datetime,
          symbol,
          type,
          side,
          average,
          price,
          status,
          amount,
          filled,
          reduceOnly,
          stopPrice,
        }) => (
          <TableRow key={id}>
            <TableCell key={"dateTime"} id={"dateTime"}>
              {moment(datetime).format("YYYY-MM-DD")}
            </TableCell>
            <TableCell key={"symbol"} id={"symbol"}>
              {symbol}
            </TableCell>
            <TableCell key={"type"} id={"type"}>
              {type}
            </TableCell>
            <TableCell key={"side"} id={"side"}>
              {side}
            </TableCell>
            <TableCell key={"average"} id={"average"}>
              {average}
            </TableCell>
            <TableCell key={"price"} id={"price"}>
              {price}
            </TableCell>
            <TableCell key={"executed"} id={"executed"}>
              {amount}
            </TableCell>
            <TableCell key={"amount"} id={"amount"}>
              {filled}
            </TableCell>
            <TableCell key={"reduceOnly"} id={"reduceOnly"}>
              {reduceOnly ? "True" : "False"}
            </TableCell>
            <TableCell key={"stopPrice"} id={"stopPrice"}>
              {stopPrice}
            </TableCell>
          </TableRow>
        )
      );
    }
  };

  return (
    <>
      <CoverScreenLoader isLoading={isLoading} />
      <Stack flexDirection={"row"} alignItems="center">
        <Typography fontWeight={"bold"}>Orders</Typography>
        <FormControl sx={{ ml: "auto" }}>
          <Select
            MenuProps={{ disableScrollLock: true }}
            labelId="symbolLabel"
            value={option}
            onChange={(e) => {
              setOption(e.target.value);
            }}
          >
            <MenuItem key={"position"} value={"position"}>
              <Typography fontWeight={"bold"}>{"Position"}</Typography>
            </MenuItem>
            <MenuItem key={"open"} value={"open"}>
              <Typography fontWeight={"bold"}>{"Open Order"}</Typography>
            </MenuItem>
            <MenuItem key={"history"} value={"history"}>
              <Typography fontWeight={"bold"}>{"Order History"}</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <OrderTable columnNames={columnOptions[option]}>
        {rows[0] ? renderRows() : ""}
      </OrderTable>
    </>
  );
};

export default AutomatedTable;
