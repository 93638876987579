import { Grid, Typography, Stack, Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Container } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import SupportSidebar from "../component/header/support/SupportSidebar";
import { useLocation } from "react-router-dom";
import useLanguage from "./../hook/useLanguage";

const SupportLayout = () => {
  const { pathname } = useLocation();
  const { direction, t } = useLanguage();

  const pages = [
    { label: "Profile", link: "profile" },
    { label: "Security", link: "security" },
    { label: "Analysis", link: "analysis" },
    { label: "Exchange", link: "exchange" },
    { label: "Notification", link: "notification" },
    { label: "Subscription", link: "subscription" },
  ];
  const currentPageName = pages.filter(
    (page) => `/support/${page.link}` === pathname
  )[0]?.label;
  return (
    <Container>
      <Stack
        sx={{ color: "white",mb:3 }}
        alignItems="center"
        flexDirection={direction}
      >
        <Box>
          <Typography variant="h4" fontWeight={"bold"}>
            {currentPageName}
          </Typography>
          <Typography
            variant="subtitle2"
            color={grey[500]}
            fontWeight="500"
          >{`${t("Account")} - ${currentPageName}`}</Typography>
        </Box>
      </Stack>
      <Grid sx={{flexDirection:direction}} container spacing={2}>
        <Grid item xs={12} md={3}>
          <SupportSidebar pages={pages} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupportLayout;
