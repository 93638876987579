import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import CenteredModal from "../CenteredModal";

const ConfirmModal = ({ open, setOpen, confirmText, onSubmit }) => {
  return (
    <CenteredModal open={open} setOpen={setOpen}>
      <Typography sx={{mb:"20px"}} fontWeight={"bold"} fontSize={"1.2rem"} textAlign="center">{confirmText}</Typography>
      <Stack flexDirection={"row"} justifyContent="center">
        <Button sx={{ mx: "5px" }} variant="contained" onClick={onSubmit}>
          Confirm
        </Button>
        <Button sx={{ mx: "5px",color:"white" }} variant="contained" color="inactive" onClick={()=>setOpen(false)}>
          Close
        </Button>
      </Stack>
    </CenteredModal>
  );
};

export default ConfirmModal;
