import { Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import frontBg from "../assets/image/frontbg.png";
import computer from "../assets/image/interface.png"

const LandingPage = () => {
  const theme=useTheme();
  const lgup=useMediaQuery(theme?.breakpoints?.up("lg"))
  return (
    <>
    <Box
      sx={{ px: 2, minHeight: "900px", height:"80vh", backgroundImage: `url(${frontBg})` }}
      justifyContent="flexEnd"
      >
      <Stack flexDirection={lgup?"row":"column"} height="100%" alignItems={"center"} justifyContent={"flex-end"}>
        <Box sx={{mr:"10%","&>*":{
          my:2
        }}}>
          <Typography
            variant="h2"
            sx={{ color: "#066163" }}
            fontWeight={"bold"}
            >
            Automated
          </Typography>
          <Typography variant="h2" fontWeight={"bold"}>
            Crypto Trading Bot
          </Typography>
          <Typography
            variant="h2"
            sx={{ color: "#066163" }}
            fontWeight={"bold"}
            >
            Made Easy
          </Typography>
          <Typography
            variant="subtitle1"
            width="600px"
            sx={{ color: grey[500] }}
            fontWeight={"bold"}
            >
            MUDAWYL empowers cryptocurrency traders to compete with professional
            algorithmic traders.
          </Typography>
        </Box>
        <Box width="685px"  component={"img"} src={computer} />

      </Stack>
    </Box>
    <Container maxWidth="lg">
      
    </Container>

            </>
  );
};

export default LandingPage;
