import * as APIHelper from "./APIHelper";

const apiName = "mudawylAccountSubscriptionRESTv1";

export const getUserSubscription = async () => {
  return await APIHelper.getAuthedData(
    "/v1/priv/account/subscription",
    apiName
  );
};
