import { Grid, Box, Typography, Stack, Button, Skeleton } from "@mui/material";
import { blue } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import KeyCard from "../../component/header/support/Exchange/KeyCard";
import SupportCard from "./../../component/support/SupportCard";
import useLanguage from "./../../hook/useLanguage";

import APIFunction from "../../api";
import CreateExchangeKeyModal from "../../component/header/support/Exchange/CreateExchangeKeyModal";
import CoverScreenLoader from "../../component/CoverScreenLoader";

const Exchange = () => {
  const { direction } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);
  const [exchangeKeyList, setExchangeKeyList] = useState([]);
  const [maxKey, setMaxKey] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const getExchangeKeyList = async () => {
    await APIFunction.listExchangeKey()
      .then((data) => data.items)
      .then((keyList) => setExchangeKeyList(keyList));

    await APIFunction.getUserSubscription().then((data) =>
      setMaxKey(data.SubscriptionType.maxApiKey)
    );
    setIsLoading(false);
  };

  useEffect(() => {
    getExchangeKeyList();
  }, []);

  return (
    <>
      <CreateExchangeKeyModal
        open={openModal}
        setOpen={setOpenModal}
        getExchangeKeyList={getExchangeKeyList}
      />
      <CoverScreenLoader isLoading={isLoading} />
      {!isLoading ? (
        <SupportCard title="Manage API Keys">
          <Grid container spacing={2}>
            {exchangeKeyList.map((key) => (
              <Grid key={key.id} item xs={12} md={6}>
                <KeyCard {...key} getExchangeKeyList={getExchangeKeyList} />
              </Grid>
            ))}
            {exchangeKeyList.length / maxKey < 1 && (
              <Grid key="add" item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: "5px",
                    borderWidth: "1px",
                    borderStyle: "dotted",
                    height: "250px",
                    borderColor: blue[300],
                    bgcolor: blue[100],
                    py: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    px: "20px",
                  }}
                >
                  <Stack direction={direction} spacing={2}>
                    <Typography fontWeight={"bold"}>
                      We store API secret keys encrypted.
                    </Typography>
                    <Button
                      variant="contained"
                      width="50%"
                      onClick={() => setOpenModal(true)}
                    >
                      ADD EXCHANGE API KEY
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            )}
          </Grid>
          <Typography mt={3} fontWeight={"bold"}>
            {`API KEY USAGE(${exchangeKeyList.length}/${maxKey})`}
          </Typography>
        </SupportCard>
      ):(<Skeleton variant="rectangular" sx={{width:"100%",backgroundColor:'grey.200',height:"50vh"}}  />
      )}
    </>
  );
};

export default Exchange;
