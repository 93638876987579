import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthedHeaderFooterLayout from "./Layout/AuthedHeaderFooterLayout";
import AuthLayout from "./Layout/AuthLayout";
import HeaderFooterLayout from "./Layout/HeaderFooterLayout";
import SupportLayout from "./Layout/SupportLayout";
import Signin from "./page/auth/SignIn";
import SignUp from "./page/auth/SignUp";
import Analysis from "./page/support/Analysis";
import Exchange from "./page/support/Exchange";
import Notification from "./page/support/Notification";
import Profile from "./page/support/Profile";
import Security from "./page/support/Security";
import Subscription from "./page/support/Subscription";
import CoverScreenLoader from "./component/CoverScreenLoader";
import useAuth from "./hook/useAuth";
import Test from "./page/Test";
import AutomatedTrading from './page/trading/AutomatedTrading';
import ManualTrading from "./page/trading/ManualTrading";
import LandingPage from "./page/LandingPage";
const Router = () => {
  const { userLoading, user } = useAuth();
  return (
    <>
      <CoverScreenLoader isLoading={userLoading} />
      <BrowserRouter>
        <Routes>
          {/* not logged in routes */}
          <Route element={<HeaderFooterLayout />}>
            <Route path="/" element={<LandingPage/>}></Route>
          </Route>
          <Route element={<AuthLayout user={user} userLoading={userLoading} />}>
            <Route path="signin" element={<Signin />} />
            <Route path="signup" element={<SignUp />} />
          </Route>
          {/* logged in routes */}
          <Route
            element={
              <AuthedHeaderFooterLayout user={user} userLoading={userLoading} />
            }
          >
            <Route path="trade">
              <Route path="order" element={<ManualTrading />} />
              <Route path="strategy" element={<AutomatedTrading/>} />
            </Route>
            <Route path="support">
              <Route element={<SupportLayout />}>
                <Route path="profile" element={<Profile />} />
                <Route path="security" element={<Security />} />
                <Route path="analysis" element={<Analysis />} />
                <Route path="exchange" element={<Exchange />} />
                <Route path="notification" element={<Notification />} />
                <Route path="subscription" element={<Subscription />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
