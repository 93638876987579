import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/system";
import React from "react";

const StatBox = ({ title, value, titleColor }) => {
  return (
    <Stack
      direction={"column"}
      alignItems="center"
      justifyContent={"center"}
      sx={{
        borderStyle: "dotted",
        borderRadius: "2px",
        borderWidth: "1px",
        width:"20%",
        py:"15px",
        borderColor: grey[300],
      }}
    >
      <Typography sx={{ color: titleColor,textAlign:"center" }}>{title}</Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
};

export default StatBox;
