import { Button, Grid, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import APIFunction from "../../../../api";
import useAuth from "../../../../hook/useAuth";
import CoverScreenLoader from "../../../CoverScreenLoader";
import CenteredModal from "../../CenteredModal";
import OptionsSelection from "./OptionsSelection";
import PlanCoverage from "./PlanCoverage";

const SubscribeModal = ({ open, setOpen, updateFunction }) => {
  const [subscriptionOption, setSubscriptionOption] = useState([]);
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [plan, setPlan] = useState("f6c421cb-6e55-4dc8-aa1c-14b09263760b");
  const { refreshCurrentUser } = useAuth();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const subscription = await APIFunction.getStripeSubscription();
      if (!subscription) {
        const { url } = await APIFunction.createStripeSubscription({
          subscriptionId: plan,
        });
        window.location.href = url;
      } else {
        await APIFunction.updateStripeSubscription({
          subscriptionId: plan,
        });

        setTimeout(async () => {
          await updateFunction();
          await refreshCurrentUser();
          setIsLoading(false);
          setOpen(false);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSubscriptionInfo = async () => {
    try {
      const APICalls = await Promise.all([
        APIFunction.listSubscriptionsPlans(),
        APIFunction.getUserSubscription(),
        APIFunction.getExchangeTotalBalance(),
      ]);
      setSubscriptionOption(APICalls[0].items);
      setPlan(APICalls[1]?.SubscriptionType.id);
      setBalance(APICalls[2].USD);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  return (
    <>
      <CoverScreenLoader isLoading={isLoading} />
      <CenteredModal sx={{ width: "70%",display:isLoading?"none":"" }} open={open} setOpen={setOpen}>
        {!isLoading && (
          <>
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Typography fontSize={25} fontWeight="bold">
                Upgrade Subscription
              </Typography>
              <Typography
                fontWeight="bold"
                fontSize={"1.2rem"}
                color={grey[600]}
              >
                If you need more info, please check FAQ for more detail.
              </Typography>
              <Typography
                sx={{
                  border: "3px solid lightgrey",
                  p: "5px",
                  my: "10px",
                  fontSize: "1.3rem",
                }}
                fontWeight={"bold"}
              >
                {`Total Account Balance: $${balance}`}
              </Typography>
            </Stack>
            <Grid container spacing={2} my={"20px"}>
              <Grid item xs={12} md={6}>
                <OptionsSelection
                  options={subscriptionOption}
                  selectedOption={plan}
                  onOptionSelect={setPlan}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PlanCoverage
                  activePlan={
                    subscriptionOption.filter((option) => option.id === plan)[0]
                  }
                />
              </Grid>
            </Grid>
            <Stack
              flexDirection={"row"}
              justifyContent="center"
              sx={{ mt: "10px" }}
            >
              <Button
                variant="contained"
                sx={{ mx: "5px", width: "140px", py: "10px" }}
                color="inactive"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ mx: "5px", width: "140px", py: "10px" }}
                onClick={handleSubmit}
              >
                Upgrade Plan
              </Button>
            </Stack>
          </>
        )}
      </CenteredModal>
    </>
  );
};

export default SubscribeModal;
