import { AppBar, Box, Button, Container, Stack, Toolbar } from "@mui/material";
import React from "react";
import useLanguage from "../../../hook/useLanguage";
import logo from "../../../assets/image/logo.png";
import { useNavigate } from "react-router-dom";
import useColorMode from '../../../hook/useColorMode';
import ColorModeSwitch from "../../ColorModeSwitch";
import UserHeaderCard from "../UserHeaderCard";
import HeaderMenu from "../HeaderMenu";

const AuthHeader = () => {
  const { direction, t } = useLanguage();
  const {mode}=useColorMode();
  const navigate=useNavigate();
  const authedButtons = [
    { label: t("Home"), link: "/" },
    { label: t("Manual Trading"), link: "/trade/order" },
    { label: t("Automated Trading"), link: "/trade/strategy" },
    { label: t("Support"), link: "/support/profile" },
  ];
  return (
    <AppBar
    position="static"
      sx={{
        flexDirection: "row",
        backgroundColor: "transparent",
        height: "80px",
        alignItems: "center",
      }}
    >
      <Container maxWidth={"lg"}>
        <Toolbar disableGutters sx={{flexDirection:direction}}>
          <Box width="60px" component={"img"} src={logo} />
          <Stack mx={2} flexDirection={direction}>
            {authedButtons.map(({ label, link }) => (
              <Button
                disableRipple
                sx={{ color: mode==="light"?"#fff":"text.primary" }}
                key={link}
                onClick={()=>navigate(link)}
              >
                {label}
              </Button>
            ))}
          </Stack>
          <ColorModeSwitch sx={{ml:"auto"}}/>
          <UserHeaderCard/>
          <HeaderMenu/>


        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AuthHeader;
