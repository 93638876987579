import moment from "moment/moment";

export const getDateBeforeTodayString = (daysAgo = 0) => {
  const today = moment().format("YYYY-MM-DD");
  const dateFrom = moment().subtract(daysAgo, "d").format("YYYY-MM-DD");

  return {
    today,
    dateFrom,
  };
};

export const getDaysbyText = (text) => {
  switch (text) {
    case "Today":
      return 0;
    case "Yesterday":
      return 1;
    case "Last 30 days":
      return 30;
    case "Last Month":
      return 60;
    case "Last Year":
      return 365;
    default:
      return 0;
  }
};

export const getDatebyText = (text) => {
  const days = getDaysbyText(text);
  return getDateBeforeTodayString(days);
};
