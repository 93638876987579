import * as APIHelper from "./APIHelper";

const apiName = "mudawylAccountSubscriptionRESTv1";

export const getStripeSubscription = async () => {
  return await APIHelper.getAuthedData(
    "/v1/priv/account/subscription/stripe",
    apiName
  );
};

export const createStripeSubscription = async (body) => {
  return await APIHelper.postAuthedData(
    "/v1/priv/account/subscription/stripe/checkout",
    body,
    apiName
  );
};

export const updateStripeSubscription = async (body) => {
  return await APIHelper.putAuthedData(
    "/v1/priv/account/subscription/stripe",
    body,
    apiName
  );
};

export const listStripeBillingHistory = async () => {
  return await APIHelper.getAuthedData(
    "/v1/priv/account/subscription/stripe/billing/history",
    apiName
  );
};
