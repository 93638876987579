import { MenuItem, Select, Stack } from "@mui/material";
import { blue, green, grey, purple, red } from "@mui/material/colors";
import React, { useState } from "react";
import OrderTable from "../../../trade/OrderTable";
import useLanguage from "./../../../../hook/useLanguage";
import StatBox from "./StatBox";
import useColorMode from "./../../../../hook/useColorMode";

const HistoryDisplay = () => {
  const [tradeType, setTradeType] = useState("auto");
  const { t, direction } = useLanguage();
  const { mode } = useColorMode();
  const inputBgColor = mode === "light" ? grey[100] : null;

  const columnNames = [
    t("Time"),
    t("Symbol"),
    t("Type"),
    t("Side"),
    t("Price"),
    t("Size"),
    t("Filled"),
    t("TP"),
    t("SL"),
    t("ACTION"),
  ];

  return (
    <>
      <Stack direction={direction} spacing={1}>
        <Select
          size={"small"}
          value={tradeType}
          onChange={(e) => setTradeType(e.target.value)}
          sx={{
            bgcolor: inputBgColor,
            ml: direction === "row" && "auto",
            width: "200px",
          }}
        >
          <MenuItem value="auto">Automated Trading</MenuItem>
          <MenuItem value="manual">Manual Trading</MenuItem>
        </Select>
        <Select
          size={"small"}
          sx={{ bgcolor: inputBgColor, width: "150px" }}
        ></Select>
        <Select
          size={"small"}
          sx={{ bgcolor: inputBgColor, width: "150px" }}
        ></Select>
        <Select
          size={"small"}
          sx={{
            bgcolor: inputBgColor,
            ml: direction !== "row" && "auto",
            width: "80px",
          }}
        ></Select>
      </Stack>
      <Stack
        direction={direction}
        spacing={1}
        my={2}
        alignItems="center"
        justifyContent={"center"}
      >
        <StatBox title="NET PROFIT%" value={"2.6%"} titleColor={purple[300]} />
        <StatBox title="NET PROFIT" value={"$1500"} titleColor={blue[300]} />
        <StatBox title="LONG" value={"15"} titleColor={green[300]} />
        <StatBox title="SHORT" value={"10"} titleColor={red[300]} />
      </Stack>
      <OrderTable columnNames={columnNames} />
    </>
  );
};

export default HistoryDisplay;
