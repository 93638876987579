import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import RoundedInput from "../general/input/RoundedInput";
import CenteredModal from "./../general/CenteredModal";
import { useState } from "react";
import { Button } from "@mui/material";
import useAuth from "../../hook/useAuth";

const ConfirmedModal = ({ username, open, setOpen,handleLogin }) => {
  const [code, setCode] = useState("");
  const { confirmSignUp } = useAuth();

  const handleSubmit = async() => {
    try{

      await confirmSignUp(username, code)
      await handleLogin()
    }catch(err){
      console.log(err)
    }
  };

  return (
    <CenteredModal open={open} setOpen={setOpen}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Your Account is Not Confirmed
      </Typography>
      <Typography fontSize={"15px"} sx={{ mt: 1 }} color={grey[500]}>
        Please check your email for confirmation code:
      </Typography>
      <RoundedInput
        sx={{ my: 2 }}
        value={code}
        name="confirmationCode"
        onChange={(e) => setCode(e.target.value)}
      />
      <Button
        onClick={handleSubmit}
        fullWidth
        variant="contained"
        sx={{ mt: 1 }}
      >
        Confirm
      </Button>
    </CenteredModal>
  );
};

export default ConfirmedModal;
