import { Box, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

const OptionsSelection = ({ selectedOption, options, onOptionSelect }) => {
  const selectionCard = ({ id, name, monthlyPriceUSD }) => {
    const isActive = selectedOption === id;

    return (
      <Stack
        flexDirection={"row"}
        alignItems="center"
        key={id}
        onClick={() => onOptionSelect(id)}
        sx={{
          "&:hover": {
            backgroundColor: grey[100],
          },
          "&:hover .radio": {
            border: "5px solid lightgreen",
          },
          cursor: "pointer",
          borderWidth: "1px",
          borderStyle: "dotted",
          backgroundColor: isActive ? grey[100] : null,
          borderColor: grey[400],
          p: "15px",
          m: "5px",
          borderRadius: "10px",
        }}
      >
        <Box
          className="radio"
          sx={{
            m: "10px",
            width: "25px",
            height: "25px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: isActive ? "lightgreen" : grey[500],
            borderRadius: "25px",
          }}
        ></Box>
        <Typography fontWeight={"bold"}>{name}</Typography>
        <Typography sx={{ ml: "auto" }} fontWeight={"bold"} fontSize={"40px"}>
          {`$${monthlyPriceUSD}`}
        </Typography>{" "}
        <Typography fontWeight={"400"} fontSize="16px" color={grey[500]}>
          /Mon
        </Typography>
      </Stack>
    );
  };
  if (!options) return <></>;
  return <Stack>{options?.map((option) => selectionCard(option))}</Stack>;
};

export default OptionsSelection;
