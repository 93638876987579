import { Paper, Typography, Stack, Divider, Box } from "@mui/material";
import React from "react";
import useLanguage from "./../../hook/useLanguage";

const SupportCard = ({ title, children,sx }) => {
  const { direction } = useLanguage();
  return (
    <Paper sx={{ py: "20px",...sx }}>
      <Stack px={"20px"} pb={"20px"} flexDirection={direction}>
        <Typography fontSize={"16px"} fontWeight={"bold"}>
          {title}
        </Typography>
      </Stack>
      <Divider />
      <Box px="20px" pt="20px">{children}</Box>
    </Paper>
  );
};

export default SupportCard;
