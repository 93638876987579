import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip
);

const StrategyHistory = ({ history, historyPeriod, setHistoryPeriod }) => {
  let lastHistoryIndex = null;
  let firstHistoryIndex = null;
  history.forEach((record, index) => {
    if (Boolean(record.winRate) && firstHistoryIndex === null) {
      firstHistoryIndex = index;
      lastHistoryIndex = index;
    } else if (Boolean(record.winRate) && firstHistoryIndex !== null) {
      lastHistoryIndex = index;
    }
  });
  const lastHistory = history[lastHistoryIndex];

  let modifiedHistory = [];

  if (firstHistoryIndex) {
    let value = history[firstHistoryIndex].winRate;
    modifiedHistory = history.map((record, index) => {
      if (!record.winRate) {
        return { ...history[index], winRate: value };
      } else {
        value = record.winRate;
        return record;
      }
    });
  }

  const data = {
    labels: modifiedHistory.map((history) => history.start),
    datasets: [
      {
        fill: true,
        data: modifiedHistory.map((history) => history.winRate),
        borderColor: "rgb(0,255,0)",
        backgroundColor: "rgb(0,255,0,0.2)",
      },
    ],
  };
  return (
    <>
      {Boolean(lastHistory) && (
        <>
          <Stack flexDirection={"row"}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography fontSize={"14px"} fontWeight={"bold"}>
                Net Profit
              </Typography>
              <Typography
                fontSize={"14px"}
                fontWeight={"bold"}
                color={grey[500]}
              >{`${
                lastHistory.netProfitPercent
                  ? (lastHistory.netProfitPercent * 100).toFixed(2)
                  : 0
              }%`}</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography fontSize={"14px"} fontWeight={"bold"}>
                Win Rate
              </Typography>
              <Typography
                fontSize={"14px"}
                fontWeight={"bold"}
                color={grey[500]}
              >{`${
                lastHistory.winRate ? lastHistory.winRate.toFixed(2) : 0
              }%`}</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography fontSize={"14px"} fontWeight={"bold"}>
                Max Draw Down
              </Typography>
              <Typography
                fontSize={"14px"}
                fontWeight={"bold"}
                color={grey[500]}
              >{`${
                lastHistory.maxDrawDown
                  ? (lastHistory.maxDrawDown * 100).toFixed(2)
                  : 0
              }%`}</Typography>
            </Box>
            <Box sx={{ width: "33%" }}>
              <FormControl fullWidth>
                <Select
                  MenuProps={{ disableScrollLock: true }}
                  labelId="strategyLabel"
                  value={historyPeriod}
                  onChange={(e) => {
                    setHistoryPeriod(e.target.value);
                  }}
                >
                  <MenuItem key={30} value={30}>
                    <Typography fontSize={"14px"} fontWeight={"bold"}>
                      {"Last 30 Days"}
                    </Typography>
                  </MenuItem>
                  <MenuItem key={100} value={100}>
                    <Typography fontSize={"14px"} fontWeight={"bold"}>
                      {"Last 100 Days"}
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
          <Box >
            <Line
              style={{ height: "100%" }}
              options={{
                responsive: true,
                radius: 0,
                scales: {
                  x: {
                    ticks: {
                      display: false,
                    },
                    reverse: true,
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    ticks: {
                      display: false,
                    },
                    stepSize: 100,
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
              data={data}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default StrategyHistory;
