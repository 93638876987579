import React, { useState, useEffect, createContext } from "react";
import { Auth } from "aws-amplify";
import APIFunction from "../api";

export const UserContext = createContext();

export default function UserContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [userLoading, setUserLoading] = useState(true);

  const refreshCurrentUser = async () => {
    try {
      const { attributes } = await Auth.currentAuthenticatedUser();
      const { SubscriptionType } = await APIFunction.getUserSubscription();
      setUser({ ...attributes, SubscriptionType });
    } catch (err) {
      console.log("Getting User error:", err);
    }
    setUserLoading(false);
  };

  useEffect(() => {
    refreshCurrentUser();
  }, []);

  return (
    <UserContext.Provider
      value={{ user, userLoading, setUser, setUserLoading, refreshCurrentUser }}
    >
      {children}
    </UserContext.Provider>
  );
}
