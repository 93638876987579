import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderColor:"transparent",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const CenteredModal = ({ open, setOpen, children, sx }) => {
  const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      backdrop={{
        timeout: 500,
      }}
    >
      <Box sx={{ ...style, ...sx }}>{children}</Box>
    </Modal>
  );
};

export default CenteredModal;
