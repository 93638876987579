import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import useLanguage from './../hook/useLanguage';

const FlagIcon = ({ language,sx }) => {
  const {t}=useLanguage()
  let label = language === "en" ? t("English") : t("Arabic");
  let src =
    language === "en"
      ? "https://flagcdn.com/w40/us.png"
      : "https://flagcdn.com/w40/ae.png";
  return (
    <Stack
      flexDirection={"row"}
      alignItems="center"
      sx={{ backgroundColor: grey[100], py: "2px", px: "4px", borderRadius:"5px", ...sx }}
    >
      <Typography sx={{ fontSize: 14, pr: 1,color:"black" }}>{label}</Typography>
      <Box component="img" width={30} src={src} alt={label} />
    </Stack>
  );
};

export default FlagIcon;
