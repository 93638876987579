import { Box, Input, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState } from "react";
import useColorMode from "../../../hook/useColorMode";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const TitledTextField = ({
  disabled,
  title,
  value,
  onChange,
  sx,
  rightComponent,
  password,
  name,
}) => {
  const { mode } = useColorMode();
  const inputBgColor = mode === "light" ? grey[100] : null;
  const [isVisible, setIsVisible] = useState(password);

  const toggleVisible = () => setIsVisible((prev) => !prev);

  return (
    <Box sx={sx}>
      <Stack flexDirection={"row"}>
        <Typography my="5px" fontSize={"16px"} fontWeight={"bold"}>
          {title}
        </Typography>
        <Box ml="auto">{rightComponent}</Box>
      </Stack>
      <Input
        disabled={disabled}
        name={name}
        type={isVisible ? "password" : "text"}
        sx={{
          bgcolor: inputBgColor,
          height: "50px",
          px: "10px",
          borderRadius: 2,
          "&:after,:before,:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
        fullWidth
        value={value}
        endAdornment={
          password ? (
            isVisible ? (
              <VisibilityOff
                sx={{ cursor: "pointer" }}
                onClick={toggleVisible}
              />
            ) : (
              <Visibility sx={{ cursor: "pointer" }} onClick={toggleVisible} />
            )
          ) : null
        }
        onChange={onChange}
      />
    </Box>
  );
};

export default TitledTextField;
