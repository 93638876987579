import { AppBar, Box, Button, Container, Stack, Toolbar } from "@mui/material";
import React from "react";
import useLanguage from "../../hook/useLanguage";
import logo from "../../assets/image/frontLogo.png";
import { useNavigate } from "react-router-dom";
import useColorMode from "../../hook/useColorMode";
import ColorModeSwitch from "../ColorModeSwitch";

const UnAuthHeader = () => {
  const { direction, t } = useLanguage();
  const { mode } = useColorMode();
  const navigate = useNavigate();
  const authedButtons = [{ label: t("About"), link: "" }];
  return (
    <AppBar
      position="absolute"
      sx={{
        flexDirection: "row",
        backgroundColor: "transparent",
        height: "80px",
        alignItems: "center",
      }}
    >
      <Container maxWidth={"xl"}>
        <Toolbar disableGutters sx={{ flexDirection: direction }}>
          <Box width="200px" component={"img"} src={logo} />
          <Stack ml="auto" flexDirection={direction} alignItems="center">
            {authedButtons.map(({ label, link }) => (
              <Button
                disableRipple
                sx={{
                  color: mode === "light" ? "#fff" : "text.primary",
                  fontSize: "18px",
                  fontWeight: "bold",
                  mx: 1,
                }}
                key={link}
                onClick={() => navigate(link)}
              >
                {label}
              </Button>
            ))}

            <ColorModeSwitch sx={{ mx: 1 }} />
            <Button
              disableRipple
              variant="outlined"
              sx={{
                color: mode === "light" ? "#fff" : "text.primary",
                borderColor: "white",
                borderRadius: "20px",
                width: "150px",
                fontSize: "20px",
                fontWeight: "bold",
                ml: 2,
              }}
              key={"signin"}
              onClick={() => navigate("signin")}
            >
              {"Log In"}
            </Button>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default UnAuthHeader;
