import { Stack } from '@mui/system';
import React from 'react';
import HistoryDisplay from '../../component/header/support/Analysis/HistoryDisplay';
import SupportCard from '../../component/support/SupportCard';

const Analysis = () => {
  return (
    <Stack spacing={5}>
    <SupportCard sx={{minHeight:"300px"}} title={"BALANCE"}></SupportCard>
    <SupportCard title={"HISTORY"}>
      <HistoryDisplay/>
    </SupportCard>
    </Stack>
  );
}

export default Analysis;
