import { Box } from "@mui/system";
import { Outlet, useNavigate } from "react-router-dom";
import AuthHeader from "../component/header/AuthedHeader/AuthHeader";
import bg from "../assets/image/header-bg.png";
// import useColorMode from "./../hook/useColorMode";
import { useEffect } from "react";

const AuthedHeaderFooterLayout = ({ user, userLoading }) => {
  const navigate = useNavigate();
  // const { mode } = useColorMode();
  useEffect(() => {
    if (!user.sub && !userLoading) {
      navigate("signin");
    }
  }, [user,userLoading, navigate]);
  return (
    <>
      <AuthHeader />
      <Box
        // display={mode === "dark" ? "none" : null}
        component={"img"}
        left={0}
        top={"-10%"}
        width="100%"
        minHeight={"35vh"}
        position="absolute"
        zIndex={-1}
        src={bg}
      ></Box>
      <Box py="15px">
        <Outlet />
      </Box>
    </>
  );
};

export default AuthedHeaderFooterLayout;
