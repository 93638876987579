import React, { useState, useEffect } from "react";
import APIFunction from "../../api";
import AutomatedPanel from "../../component/trade/AutomatedPanel";
import TradeLayout from "./../../Layout/TradeLayout";
import AutomatedDisplay from "../../component/trade/AutomatedDisplay";
import OrderTable from "../../component/trade/OrderTable";
import useLanguage from "./../../hook/useLanguage";
import { Typography } from "@mui/material";
import AutomatedTable from "../../component/trade/AutomatedTable";

const AutomatedTrading = () => {
  const [exchangeKeys, setExchangeKeys] = useState([]);
  const [currentKey, setCurrentKey] = useState("");
  const [strategyList, setStrategyList] = useState([]);
  const [currentStrategy, setCurrentStrategy] = useState("");
  const [profileList, setProfiles] = useState([]);
  const [currentProfile, setCurrentProfile] = useState("");
  const { t } = useLanguage();

  const getExchangeKeys = async () => {
    const { items: keys } = await APIFunction.listExchangeKey();
    const automatedKeys = keys.filter(
      (key) => key.strategyType === "AUTOMATED"
    );

    setExchangeKeys(automatedKeys);
    setCurrentKey(automatedKeys[0]?automatedKeys[0].id:"");
  };
  const getStrategies = async () => {
    const { items: strategies } = await APIFunction.listStrategyAutomated();
    setStrategyList(strategies);
    setCurrentStrategy(strategies[0]?.id);
  };

  const getStategyRiskProfile = async () => {
    const { items: profiles } = await APIFunction.listStrategyRiskProfile();
    setProfiles(profiles);
    setCurrentProfile(profiles[0]?.id);
  };

 

  useEffect(() => {
    getExchangeKeys();
    getStrategies();
    getStategyRiskProfile();
  }, []);

  return (
    <TradeLayout
      exchangeKeys={exchangeKeys}
      currentKey={currentKey}
      setCurrentKey={setCurrentKey}
    >
      <AutomatedDisplay {...{ currentKey }} />
      <AutomatedPanel
        {...{
          currentKey,
          currentProfile,
          currentStrategy,
          setCurrentStrategy,
          setCurrentProfile,
          profileList,
          strategyList,
        }}
      />
      <AutomatedTable currentKey={currentKey} />
    </TradeLayout>
  );
};

export default AutomatedTrading;
