import { Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import APIFunction from "../../../../api";
import CenteredModal from "../../../general/CenteredModal";
import TitledTextField from "./../../../general/input/TitledTextField";

const CreateExchangeKeyModal = ({ open, setOpen, getExchangeKeyList }) => {
  const initalState = {
    exchangeId: "BINANCE",
    tradeType: "FUTURES",
    strategyType: "MANUAL",
    name: "Default",
    apiKey: "",
    apiSecretKey: "",
  };
  const [keyInfo, setKeyInfo] = useState(initalState);
  const [error, setError] = useState("");
  const [ipAddressList, setIpAddressList] = useState([]);

  const handleKeyInfoChange = (e) => {
    setKeyInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const getWhiteList = async () => {
      const whiteList = await APIFunction.listWhitelistIPAddress();

      setIpAddressList(whiteList);
    };

    getWhiteList();
  }, []);

  const getIpString=() => {
    let ipString = "";

    ipAddressList.forEach((ip) => (ipString += `${ip};`));

    return ipString;
  }

  const handleSubmitKeyInfo = async () => {
    try {
      setError("");
      await APIFunction.createExchangeKey(keyInfo);
      await getExchangeKeyList();
      setKeyInfo(initalState);
      setOpen(false);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleCancel = () => {
    setKeyInfo(initalState);
    setError("");
    setOpen(false);
  };

  return (
    <CenteredModal sx={{ width: "30%" }} open={open} setOpen={setOpen}>
      <Typography sx={{ mb: "20px" }} fontWeight={"bold"} fontSize={24}>
        Create Exchange API Key
      </Typography>
      <TitledTextField
        title="Name"
        name="name"
        onChange={handleKeyInfoChange}
        value={keyInfo.name}
      />
      <Typography my="5px" fontWeight={"bold"}>
        Strategy Type
      </Typography>
      <Select
        sx={{ width: "40%", minWidth: "150px" }}
        value={keyInfo.strategyType}
        name="strategyType"
        onChange={handleKeyInfoChange}
      >
        <MenuItem value="MANUAL">{"Manual Trading"}</MenuItem>
        <MenuItem value="AUTOMATED">{"Automated Trading"}</MenuItem>
      </Select>
      <TitledTextField
        title="API Key"
        name="apiKey"
        onChange={handleKeyInfoChange}
        value={keyInfo.apiKey}
      />
      <TitledTextField
        title="API Secret Key"
        name="apiSecretKey"
        onChange={handleKeyInfoChange}
        value={keyInfo.apiSecretKey}
      />
      <TitledTextField
        title="White List IP Addresses"
        name="apiSecretKey"
        value={getIpString()}
      />
      <Typography color="red">{error}</Typography>
      <Stack mt="10px" flexDirection={"row"}>
        <Button
          sx={{ mx: "10px", my: "5px", ml: "auto" }}
          variant="contained"
          onClick={handleSubmitKeyInfo}
        >
          Create Key
        </Button>
        <Button
          sx={{ mx: "10px", my: "5px" }}
          variant="contained"
          color="inactive"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Stack>
    </CenteredModal>
  );
};

export default CreateExchangeKeyModal;
