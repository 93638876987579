import {
  Box,
  Container,
  Select,
  Stack,
  Typography,
  MenuItem,
  Paper,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import useLanguage from "./../hook/useLanguage";
import { grey } from "@mui/material/colors";
import useColorMode from "./../hook/useColorMode";
import OrderTable from "../component/trade/OrderTable";

const TradeLayout = ({
  manual,
  children,
  exchangeKeys,
  currentKey,
  setCurrentKey,
}) => {
  const { t, direction } = useLanguage();
  const { mode } = useColorMode();
  const modeLabel = manual ? t("MANUAL") : t("STRATEGY");
  const [tradeOption, setTradeOption] = useState("FUTURES");

  const selectStyle = {
    backgroundColor: mode === "light" ? "white" : "paper",
    textAlign: "left",
    borderRadius: "5px",
    fontSize: "14px",
    height: "45px",
    width: "130px",
  };
  const paperElevation = 5;
  const paperStyle = { px: 4, py: 3 };

  return (
    <Container>
      <Stack
        sx={{ color: "white" }}
        alignItems="center"
        flexDirection={direction}
      >
        <Box>
          <Typography variant="h4" fontWeight={"bold"}>
            {modeLabel}
          </Typography>
          <Typography
            variant="subtitle2"
            color={grey[500]}
            fontWeight="500"
          >{`${t("TRADE")} - ${
            manual ? t("MANUAL") : t("AUTOMATED")
          }`}</Typography>
        </Box>
        <Select
          MenuProps={{ disableScrollLock: true }}
          sx={{
            ml: direction === "row" ? "auto" : "10px",
            mr: direction === "row" ? null : "auto",
            ...selectStyle,
          }}
          value={tradeOption}
          onChange={(e) => setTradeOption(e.target.value)}
        >
          <MenuItem sx={{ fontSize: "14px" }} value={"FUTURES"}>
            {t("FUTURES")}
          </MenuItem>
          {/* <MenuItem sx={{ fontSize: "14px" }} value={"SPOT"}>
            {t("SPOT")}
          </MenuItem> */}
        </Select>
        <Select
          MenuProps={{ disableScrollLock: true }}
          sx={{
            ...selectStyle,
            ml: direction === "row" ? "10px" : null,
            width: "220px",
          }}
          value={currentKey}
          onChange={(e) => setCurrentKey(e.target.value)}
        >
          {exchangeKeys.map((exchangeKey) => (
            <MenuItem
              sx={{ fontSize: "14px" }}
              key={exchangeKey.id}
              value={exchangeKey.id}
            >
              {`${exchangeKey.exchangeId} - ${exchangeKey.name}`}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Grid container spacing={3} py="5vh" sx={{ flexDirection: direction }}>
        <Grid item xs={12} lg={7}>
          <Paper
            elevation={paperElevation}
            sx={{ minHeight: "70vh", ...paperStyle }}
          >
            {children[0]}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Paper
            elevation={paperElevation}
            sx={{ minHeight: "70vh", ...paperStyle, px: 0 }}
          >
            {children[1]}
          </Paper>
        </Grid>
      </Grid>
      <Paper
        elevation={paperElevation}
        sx={{ minHeight: "30vh", ...paperStyle }}
      >
        {children[2]}
      </Paper>
    </Container>
  );
};

export default TradeLayout;
