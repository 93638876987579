import { MenuItem, Stack, Typography, Button, Box, Menu } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import APIFunction from "../../api";
import { getDatebyText } from "../../helper/date";
import CoverScreenLoader from "../CoverScreenLoader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip
);

const AutomatedDisplay = ({ currentKey }) => {
  const dateOptions = [
    "Today",
    "Yesterday",
    "Last 30 days",
    "Last Month",
    "Last Year",
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [dateIndex, setDateIndex] = useState(2);
  const [strategyHistory, setStrategyHistory] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateSelect = async (index) => {
    setIsLoading(true);
    setDateIndex(index);
    const dates = getDatebyText(dateOptions[index]);
    handleClose();
    const { items: history } = await APIFunction.listExchangeKeyBalanceHistory(
      currentKey,
      {
        start: dates.dateFrom,
        limit: 1000,
      }
    );
    let reversedHistory=history
    setStrategyHistory(reversedHistory);
    setIsLoading(false);
  };

  const data = {
    labels: strategyHistory.map((history) => history.date),
    datasets: [
      {
        fill: true,
        data: strategyHistory.map((history) => history.totalUSD),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgb(255, 99, 132,0.2)",
      },
    ],
  };

  const getDiff = () => {
    return (
      ((strategyHistory[0]?.totalUSD -
        strategyHistory[strategyHistory.length - 1]?.totalUSD) /
        strategyHistory[strategyHistory.length - 1]?.totalUSD) *
      100
    );
  };

  useEffect(() => {
    const getStrategyHistory = async () => {
      if (currentKey) {
        const { items: history } =
          await APIFunction.listExchangeKeyBalanceHistory(currentKey);
          await APIFunction.listSt
        setStrategyHistory(history);
      }
      setIsLoading(false);
    };
    getStrategyHistory();
  }, [currentKey]);

  return (
    <>
      <CoverScreenLoader isLoading={isLoading} />
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={"bold"}>PERFORMANCE</Typography>
        <Box
          sx={{
            width: "125px",
            backgroundColor: grey[200],
            borderRadius: "4px",
          }}
        >
          <Button
            id="dateMenu"
            fullWidth
            disableRipple
            aria-controls={open ? "dataMenu" : undefined}
            aria-haspopup="true"
            sx={{ fontWeight: "bold" }}
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {dateOptions[dateIndex]}
          </Button>
          <Menu
            id="dateMenu"
            anchorEl={anchorEl}
            disableScrollLock
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "dataMenu",
            }}
          >
            {dateOptions.map((date, index) => {
              const isActive = index === dateIndex;
              const activeStyle = {
                color: "white",
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              };

              return (
                <MenuItem
                  sx={isActive ? activeStyle : null}
                  onClick={() => handleDateSelect(index)}
                  key={date}
                >
                  {date}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      </Stack>
      {
        strategyHistory.length ?
        <Stack sx={{ mt: "5px" }} flexDirection={"row"} alignItems="center">
        <Typography
          fontWeight={"bold"}
          fontSize="1.2rem"
          sx={{ mr: "5px", mb: "2%" }}
          color={grey[500]}
        >
          $
        </Typography>
        <Typography fontSize={"2.5rem"} fontWeight={"bold"}>{`${
          strategyHistory.length ? strategyHistory[0]?.totalUSD?.toFixed(2) : ""
        }`}</Typography>
        <Typography
          color="white"
          fontSize={"1.2rem"}
          sx={{
            ml: "10px",
            py: "2px",
            px: "15px",
            bgcolor: getDiff() >= 0 ? "green" : "red",
            borderRadius: "5px",
          }}
          fontWeight={"bold"}
        >{`${
          strategyHistory.length ? `${getDiff()?.toFixed(1)}%` : ""
        }`}</Typography>
      </Stack>:""

      }
   
      <Typography fontWeight={"bold"} sx={{ color: grey[500] }}>
        ACCOUNT BALANCE
      </Typography>
      {Boolean(strategyHistory.length) && (
        <Line
          options={{
            responsive: true,
            radius: 0,
            scales: {
              x: {
                reverse:true,
                grid: {
                  display: false,
                  
                },
              },
              y: {
                stepSize: 100,
                grid: {
                  display: false,
                },
              },
            },
          }}
          data={data}
        />
      )}
    </>
  );
};

export default AutomatedDisplay;
