import React, { useState, useMemo, createContext } from "react";
import { ThemeProvider} from "@emotion/react";
import { createTheme } from "@mui/material";
import getCurrentTheme from "../theme/theme";

export const ColorModeContext = createContext();

export default function ColorModeContextProvider({ children }) {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );
  const theme = useMemo(() => createTheme(getCurrentTheme(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={{colorMode,mode}}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}
