import { Auth } from "aws-amplify";
import { UserContext } from "../context/UserContextProvider";
import { useContext } from "react";

const useAuth = () => {
  const { user, setUser, userLoading, setUserLoading, refreshCurrentUser } =
    useContext(UserContext);

  const signUp = async ({ password, email, family_name, given_name }) => {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional
          family_name,
          given_name,
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
      console.log(user);
    } catch (error) {
      console.log("error signing up:", error);
    }
  };

  async function confirmSignUp(username, code) {
    setUserLoading(true);
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      console.log("error confirming sign up", error);
    }
    setUserLoading(false);
  }
  async function resendConfirmationCode(username) {
    try {
      await Auth.resendSignUp(username);
      console.log("code resent successfully");
    } catch (err) {
      console.log("error resending code: ", err);
    }
  }
  async function signIn(username, password) {
    setUserLoading(true);
    const { attributes } = await Auth.signIn(username, password).catch(
      (err) => {
        setUserLoading(false);
        throw err;
      }
    );
    setUser(attributes);
    setUserLoading(false);
  }
  async function signOut() {
    setUserLoading(true);

    try {
      await Auth.signOut();
      setUser({});
    } catch (error) {
      console.log("error signing out: ", error);
    }
    setUserLoading(false);
  }
  async function changePassword(oldPassword, newPassword) {
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  async function updateEmail(email) {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, { email: email });
    const { attributes } = await Auth.currentAuthenticatedUser();
    setUser(attributes);
  }

  return {
    signUp,
    confirmSignUp,
    resendConfirmationCode,
    updateEmail,
    changePassword,
    signIn,
    signOut,
    user,
    userLoading,
    setUserLoading,
    refreshCurrentUser,
  };
};

export default useAuth;
