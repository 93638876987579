import React, { createContext, useState } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ar from "../assets/translation/ar.json";
import en from "../assets/translation/en.json";
import { useTranslation } from "react-i18next";

const resources = { en, ar };

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export const LanguageContext = createContext();

const LanguageContextProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const [direction, setDirection] = useState("row");

  const toggleLanguage = () => {
    let newLanguage = language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
    setDirection(newLanguage === "ar"?"row-reverse":"row");
  };

  return (
    <LanguageContext.Provider
      value={{ language, toggleLanguage, direction, t }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
