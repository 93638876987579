import { Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import TitledTextField from "../../component/general/input/TitledTextField";
import { useNavigate } from "react-router-dom";
import useAuth from "./../../hook/useAuth";
import ConfirmedModal from "../../component/auth/ConfirmedModal";

const Signin = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [info, setInfo] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const handleInfoChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleLogin = () => {
    signIn(info.email, info.password)
      .then(() => navigate("/trade/order"))
      .catch((err) => {
        if (err.code === "UserNotConfirmedException") {
          setOpen(true);
        } else {
          setError("Invalid Username Or Password");
        }
      });
  };

  return (
    <>
      <ConfirmedModal
        open={open}
        setOpen={setOpen}
        username={info.email}
        handleLogin={handleLogin}
      />
      <Typography variant="h5">Sign In</Typography>
      <Stack flexDirection={"row"} alignItems="center" mb="30px">
        <Typography
          sx={{
            color: grey[400],
          }}
        >
          New Here?
        </Typography>
        <Button onClick={() => navigate("/signup")}>Create an Account</Button>
      </Stack>
      <TitledTextField
        name="email"
        sx={{ width: "80%", my: "15x" }}
        title="Email"
        onChange={handleInfoChange}
      />
      <TitledTextField
        name={"password"}
        sx={{ width: "80%", my: "15px" }}
        title="Password"
        password
        onChange={handleInfoChange}
        rightComponent={
          <Button sx={{ fontWeight: "bold" }}>Forgot Password</Button>
        }
      />
      <Button
        onClick={handleLogin}
        variant="contained"
        sx={{ width: "80%", mt: "50px" }}
      >
        Continue
      </Button>
    </>
  );
};

export default Signin;
