import { Button, Stack, TextField, Typography } from "@mui/material";
import { blue, green, grey, orange } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useState } from "react";
import APIFunction from "../../../../api";

const KeyCard = ({
  id,
  title,
  name,
  tradeType,
  strategyType,
  apiKey,
  getExchangeKeyList,
}) => {
  const [nameText, setNameText] = useState(name);
  const [editting, setEditting] = useState(false);
  const tradeText = tradeType === "SPOT" ? "SPOT" : "FUTURES";
  const strategyText =
    strategyType === "AUTOMATED" ? "Automated Trading" : "Manual Trading";
  const strategyColor = strategyType === "auto" ? green : orange;
  return (
    <Box
      sx={{
        borderRadius: "5px",
        borderWidth: "1px",
        borderStyle: "dotted",
        height: "250px",
        borderColor: grey[300],
        py: "10px",
        px: "20px",
        "&>*": { my: "5px" },
      }}
    >
      <Typography sx={{ my: "10px !important" }} fontWeight={"bold"}>
        {title}
      </Typography>
      <Stack flexDirection={"row"} sx={{ height: "40px" }} alignItems="center">
        <Typography sx={{ mr: "10px" }}>{`Name:`}</Typography>
        {!editting ? (
          <Typography>{`${name}`}</Typography>
        ) : (
          <TextField
            variant="outlined"
            size="small"
            margin="none"
            value={nameText}
            onChange={(e) => setNameText(e.target.value)}
          />
        )}
      </Stack>
      <Typography>
        {"Trade Type: "}
        <Typography
          variant="span"
          sx={{ py: "3px", px: "8px", borderRadius: "4px" }}
          fontWeight={"bold"}
          color={blue[400]}
          bgcolor={blue[100]}
        >
          {tradeText}
        </Typography>
      </Typography>
      <Typography>
        {"Strategy Type: "}
        <Typography
          variant="span"
          sx={{ py: "3px", px: "8px", borderRadius: "4px" }}
          fontWeight={"bold"}
          color={strategyColor[400]}
          bgcolor={strategyColor[100]}
        >
          {strategyText}
        </Typography>
      </Typography>
      <Typography>{`API KEY: ${apiKey.slice(0, 15) + "..."}`}</Typography>
      <Stack mt={"20px !important"} direction={"row"} spacing={1}>
        <Button
          variant="contained"
          color="inactive"
          onClick={async() => {
           await  APIFunction.deleteExchangeKey(id);
           await getExchangeKeyList();
          }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="inactive"
          onClick={async () => {
            if (editting) {
              await APIFunction.updateExchangeKey({ name: nameText }, id);
              await getExchangeKeyList();
            }
            setEditting((prev) => !prev);
          }}
        >
          {editting ? "Confirm" : "Rename"}
        </Button>
      </Stack>
    </Box>
  );
};

export default KeyCard;
