import React, { useEffect, useState } from "react";
import SupportCard from "../../component/support/SupportCard";
import {
  Box,
  Stack,
  Typography,
  Button,
  Divider,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
  TableBody,
  Skeleton,
} from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";
import useLanguage from "../../hook/useLanguage";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import CoverScreenLoader from "../../component/CoverScreenLoader";
import APIFunction from "./../../api/index";

const Notification = () => {
  const { direction } = useLanguage();
  const [isLoading, setIsLoading] = useState(true);
  const [telegram, setTelegram] = useState({});
  const [notification, setNotification] = useState({
    weeklyReport: false,
    monthlyReport: false,
    emailNewOrder: false,
    emailTakeProfit: false,
    emailStopLoss: false,
    telegramNewOrder: false,
    telegramTakeProfit: false,
    telegramStopLoss: false,
  });
  const buttonAlign = {
    ml: direction === "row" ? "auto" : null,
    mr: direction === "row" ? null : "auto",
  };

  const getSettings = async () => {
    const settings = await Promise.all([
      APIFunction.getAlertMethod(),
      APIFunction.getNotificationSettings(),
    ]);
    if (settings[1] === null) {
      const response = await APIFunction.updateNotificationSettings({
        weeklyReport: false,
        monthlyReport: false,
        emailNewOrder: false,
        emailTakeProfit: false,
        emailStopLoss: false,
        telegramNewOrder: false,
        telegramTakeProfit: false,
        telegramStopLoss: false,
      });
      setNotification(response);
    } else {
      setNotification(settings[1]);
    }
    setTelegram(settings[0]);

    setIsLoading(false);
  };

  const setNotificationChange = (e) => {
    setNotification((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleNotificationChangeSubmit = async () => {
    setIsLoading(true);
    const response = await APIFunction.updateNotificationSettings(notification);
    setNotification(response);
    setIsLoading(false);
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      <CoverScreenLoader isLoading={isLoading} />
      {!isLoading ? (
        <>
          <SupportCard title="Strategy">
            <Box
              sx={{
                borderStyle: "dotted",
                borderColor: blue[500],
                borderWidth: "1px",
                borderRadius: 2,
                bgcolor: blue[50],
                p: "12px",
                mt: "20px",
              }}
            >
              <Stack direction={direction} alignItems="center" spacing={1}>
                <Box height={"80px"} pt="10px" width={"50px"} color={blue[400]}>
                  <SmartToyIcon />
                </Box>
                <Box color="black">
                  <Typography fontWeight={"bold"}>{`${
                    telegram.telegramAuthCode
                      ? telegram.telegramAuthCode
                      : "auth 4a4ec3"
                  }`}</Typography>
                  <Typography>
                    The alert bot will ask you for a unique code to link your
                    Telegram account. Reply the bot with{" "}
                    <Typography variant="span" fontWeight={"bold"}>
                      {`${
                        telegram.telegramAuthCode
                          ? telegram.telegramAuthCode
                          : "auth 4a4ec3"
                      }`}
                    </Typography>
                    .
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  sx={{ width: "30%", px: "30px", bgcolor: green[500] }}
                  onClick={() => {
                    window.location.href = telegram.telegramBotUrl;
                  }}
                >
                  Open Telegram Bot
                </Button>
              </Stack>
            </Box>
            <Divider sx={{ borderStyle: "dotted", my: "15px" }} />
            <Stack direction={direction} spacing={1}>
              <Checkbox
                checked={notification.weeklyReport}
                name="weeklyReport"
                onChange={setNotificationChange}
              />
              <Box>
                <Typography fontWeight={"bold"}>
                  Weekly Trading Report
                </Typography>
                <Typography color={grey[500]}>
                  Select if you want to receive weekly trading report.
                </Typography>
              </Box>
            </Stack>
            <Divider sx={{ borderStyle: "dotted", my: "15px" }} />
            <Stack direction={direction} spacing={1}>
              <Checkbox
                checked={notification.monthlyReport}
                name="monthlyReport"
                onChange={setNotificationChange}
              />
              <Box>
                <Typography fontWeight={"bold"}>
                  Monthly Trading Report
                </Typography>
                <Typography color={grey[500]}>
                  Select if you want to receive monthly trading report.
                </Typography>
              </Box>
            </Stack>
            <Divider sx={{ borderStyle: "dotted", my: "15px" }} />
            <TableContainer sx={{ my: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={"bold"} fontSize={"1rem"}>
                        Alert Method
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              notification.emailNewOrder &&
                              notification.emailTakeProfit &&
                              notification.emailStopLoss
                            }
                            onChange={(e) => {
                              const value = e.target.checked;
                              setNotification((prev) => ({
                                ...prev,
                                emailNewOrder: value,
                                emailTakeProfit: value,
                                emailStopLoss: value,
                              }));
                            }}
                          />
                        }
                        label="Email"
                      />
                    </TableCell>
                    <TableCell align="right">
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!telegram.telegramActive}
                            checked={
                              notification.telegramNewOrder &&
                              notification.telegramTakeProfit &&
                              notification.telegramStopLoss
                            }
                            onChange={(e) => {
                              const value = e.target.checked;
                              setNotification((prev) => ({
                                ...prev,
                                telegramNewOrder: value,
                                telegramTakeProfit: value,
                                telegramStopLoss: value,
                              }));
                            }}
                          />
                        }
                        label="Telegram"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>New Order</TableCell>
                    <TableCell align="right">
                      <Checkbox
                        sx={{ mr: "58px" }}
                        name="emailNewOrder"
                        onChange={setNotificationChange}
                        checked={notification.emailNewOrder}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        disabled={!telegram.telegramActive}
                        sx={{ mr: "83px" }}
                        name="telegramNewOrder"
                        onChange={setNotificationChange}
                        checked={notification.telegramNewOrder}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Take Profit</TableCell>
                    <TableCell align="right">
                      <Checkbox
                        sx={{ mr: "58px" }}
                        name="emailTakeProfit"
                        onChange={setNotificationChange}
                        checked={notification.emailTakeProfit}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        sx={{ mr: "83px" }}
                        disabled={!telegram.telegramActive}
                        name="telegramTakeProfit"
                        onChange={setNotificationChange}
                        checked={notification.telegramTakeProfit}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Stop-loss</TableCell>
                    <TableCell align="right">
                      <Checkbox
                        sx={{ mr: "58px" }}
                        name="emailStopLoss"
                        onChange={setNotificationChange}
                        checked={notification.emailStopLoss}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        sx={{ mr: "83px" }}
                        disabled={!telegram.telegramActive}
                        name="telegramStopLoss"
                        onChange={setNotificationChange}
                        checked={notification.telegramStopLoss}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Stack direction={direction} sx={{ mt: 2 }} spacing={1}>
              <Button
                sx={buttonAlign}
                variant="contained"
                color="inactive"
                onClick={getSettings}
              >
                Discard
              </Button>
              <Button
                variant="contained"
                onClick={handleNotificationChangeSubmit}
              >
                Saves Changes
              </Button>
            </Stack>
          </SupportCard>
        </>
      ):(<Skeleton variant="rectangular" sx={{width:"100%",backgroundColor:'grey.200',height:"80vh"}}  />)}
    </>
  );
};

export default Notification;
