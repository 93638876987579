import React from "react";
import { LightMode, DarkMode } from "@mui/icons-material";
import useColorMode from "./../hook/useColorMode";
import { Box } from "@mui/material";

const ColorModeSwitch = ({sx}) => {
  const { mode, colorMode } = useColorMode();
  return (
    <Box display="flex"  sx={{ cursor: "pointer",...sx }} onClick={colorMode.toggleColorMode}>
      {mode === "light" ? <LightMode /> : <DarkMode />}
    </Box>
  );
};

export default ColorModeSwitch;
