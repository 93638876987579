import { Auth, API } from "aws-amplify";

export const getData = async (path, apiName) => {
  return await API.get(apiName, path);
};

export const postData = async (path, body, apiName) => {
  const myInit = { body };

  return await API.post(apiName, path, myInit);
};

export const putData = async (path, body, apiName) => {
  const myInit = { body };

  return await API.put(apiName, path, myInit);
};

export const patchData = async (path, body, apiName) => {
  const myInit = { body };

  return await API.patch(apiName, path, myInit);
};

export const deleteData = async (path, apiName) => {
  return await API.del(apiName, path);
};


/// Authed Data


const getTokenedHeader = async () => {
  return {
    Authorization: `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`,
  };
};

export const getAuthedData = async (path, apiName) => {
  const myInit = {
    headers: await getTokenedHeader(),
  };
  return await API.get(apiName, path, myInit);
};

export const postAuthedData = async (path, body, apiName) => {
  const myInit = {
    body,
    headers: await getTokenedHeader(),
  };

  return await API.post(apiName, path, myInit);
};

export const putAuthedData = async (path, body, apiName) => {
  const myInit = {
    body,
    headers: await getTokenedHeader(),
  };

  return await API.put(apiName, path, myInit);
};

export const patchAuthedData = async (path, body, apiName) => {
  const myInit = {
    body,
    headers: await getTokenedHeader(),
  };

  return await API.patch(apiName, path, myInit);
};

export const deleteAuthedData = async (path,body, apiName) => {
  const myInit = {
    headers: await getTokenedHeader(),
    body
  };
  return await API.del(apiName, path, myInit);
};
