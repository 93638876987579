import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import useLanguage from "../../../hook/useLanguage";

const ProfileRow = ({ id, title, children }) => {
  const { direction } = useLanguage();

  return (
    <Grid key={id} container sx={{ flexDirection: direction }} py={1}>
      <Grid item xs={4}>
        <Stack
          direction={direction}
          height="100%"
          alignItems="center"
          spacing={3}
        >
          <Typography>{title}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <Stack direction={direction} alignItems="center" spacing={3}>
          {children}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProfileRow;
