import * as APIHelper from "./APIHelper";

export const getExchangeTicker = async () => {
  return await APIHelper.getData(
    "/v1/pub/exchange/ticker",
    "mudawylPublicExchangeRESTv1"
  );
};

export const listSubscriptionsPlans = async () => {
  return await APIHelper.getData(
    "/v1/pub/subscription/list",
    "mudawylPublicSubscriptionRESTv1"
  );
};

export const listStrategyRiskProfile = async () => {
  return await APIHelper.getData(
    "/v1/pub/strategy/profile/list",
    "mudawylPublicStrategyRESTv1"
  );
};

export const listStrategyAutomated = async () => {
  return await APIHelper.getData(
    "/v1/pub/strategy/list",
    "mudawylPublicStrategyRESTv1"
  );
};

export const listStrategyHistory = async (strategyId, limit=30) => {
  return await APIHelper.getData(
    `/v1/pub/strategy/${strategyId}/history?limit=${limit}`,
    "mudawylPublicStrategyRESTv1"
  );
};
