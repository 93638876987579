import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import APIFunction from "../../../api";

const ManualOrderTab = () => {
  const [type, setType] = useState("LIMIT");
  const [ticker, setTicker] = useState({
    high: 0,
  });
  const [amountCalculation, setAmountCalculation] = useState("mb");
  const [stats, setStats] = useState({
    price: ticker.high,
    amount: 0,
    total: 0,
    marginBalance: 0.05,
    riskOfEquity: 1,
    marginPosition: "CROSSED",
    leverage: 1,
  });

  const onStatsChange = async (e, name) => {
    let value = e.target.value;
    setStats((prev) => ({ ...prev, [name]: e.target.value }));
    const newTicker = await APIFunction.getExchangeTicker();

    if (name === "amount") {
      setStats((prev) => ({
        ...prev,
        total: newTicker.high * value,
      }));
    }else if(name==="total"){
      setStats((prev) => ({
        ...prev,
        amount: value / newTicker.high ,
      }));
    }
  };

  useEffect(() => {
    const updateTicker = async () => {
      try {
        const newTicker = await APIFunction.getExchangeTicker();
        setTicker(newTicker);
        setStats((prev) => ({ ...prev, price: newTicker.high }));
      } catch (err) {
        console.log(err);
      }
    };

    updateTicker();
  }, []);
  return (
    <Box
      sx={{
        "&>*": {
          mb: "15px",
          "&>.MuiFormControl-root": {
            width: "50%",
          },
        },
      }}
    >
      {/* LINE 1 */}
      <Stack direction={"row"} justifyContent="space-between" spacing={1}>
        <FormControl sx={{ width: "40%" }}>
          <InputLabel id="symbolLabel">SYMBOL</InputLabel>
          <Select
            MenuProps={{ disableScrollLock: true }}
            labelId="symbolLabel"
            value={"BTCUSDT"}
            label={"SYMBOL"}
          >
            <MenuItem key={"BTCUSDT"} value={"BTCUSDT"}>
              <Typography fontWeight={"bold"}>{"BTCUSDT"}</Typography>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel id="symbolLabel">TYPE</InputLabel>
          <Select
            MenuProps={{ disableScrollLock: true }}
            labelId="symbolLabel"
            value={type}
            onChange={(e) => setType(e.target.value)}
            label={"TYPE"}
          >
            <MenuItem key={"LIMIT"} value={"LIMIT"}>
              <Typography fontWeight={"bold"}>{"LIMIT"}</Typography>
            </MenuItem>
            <MenuItem key={"MARKET"} value={"MARKET"}>
              <Typography fontWeight={"bold"}>{"MARKET"}</Typography>
            </MenuItem>
            <MenuItem key={"TRAILING_STOP"} value={"TRAILING_STOP"}>
              <Typography fontWeight={"bold"}>{"TRAILING STOP"}</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {/* LINE 2 */}
      <FormControl sx={{ mb: "15px" }} fullWidth>
        <InputLabel id="symbolLabel">Amount Calculation</InputLabel>
        <Select
          MenuProps={{ disableScrollLock: true }}
          labelId="symbolLabel"
          value={amountCalculation}
          onChange={(e) => setAmountCalculation(e.target.value)}
          label={"Amount Calculation"}
        >
          <MenuItem key={"mb"} value={"mb"}>
            <Typography fontWeight={"bold"}>{"MARGIN BALANCE %"}</Typography>
          </MenuItem>
          <MenuItem key={"st"} value={"st"}>
            <Typography fontWeight={"bold"}>
              {"SPECIFIC TOTAL, AMOUNT"}
            </Typography>
          </MenuItem>
          <MenuItem key={"risk"} value={"risk"}>
            <Typography fontWeight={"bold"}>
              {"RISK OF EQUITY PER TRADE"}
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
      {/* LINE 3 */}
      <Stack direction={"row"} justifyContent="space-between" spacing={1}>
        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            type="number"
            sx={{ backgroundColor: type === "MARKET" && grey[200] }}
            disabled={type === "MARKET"}
            value={stats.price}
            onChange={(e) => onStatsChange(e, "price")}
            label={"Price"}
          />
        </FormControl>
        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            type="number"
            value={stats.amount}
            sx={{
              backgroundColor:
                (amountCalculation === "risk" || amountCalculation === "mb") &&
                grey[200],
            }}
            disabled={
              amountCalculation === "risk" || amountCalculation === "mb"
            }
            onChange={(e) => onStatsChange(e, "amount")}
            label={"Amount(BTC)"}
          />
        </FormControl>
      </Stack>
      {/* LINE 4 */}
      <Stack direction={"row"} justifyContent="space-between" spacing={1}>
        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            type="number"
            value={stats.total}
            sx={{
              backgroundColor:
                (amountCalculation === "risk" || amountCalculation === "mb") &&
                grey[200],
            }}
            disabled={
              amountCalculation === "risk" || amountCalculation === "mb"
            }
            onChange={(e) => onStatsChange(e, "total")}
            label={"Total(USDT)"}
          />
        </FormControl>
        <FormControl sx={{ width: "50%" }}>
          <InputLabel id="symbolLabel">MARGIN BALANCE %</InputLabel>
          <Select
            MenuProps={{ disableScrollLock: true }}
            value={stats.marginBalance}
            sx={{
              backgroundColor:
                (amountCalculation === "st" || amountCalculation === "risk") &&
                grey[200],
            }}
            disabled={
              amountCalculation === "st" || amountCalculation === "risk"
            }
            onChange={(e) => onStatsChange(e, "marginBalance")}
            label={"MARGIN BALANCE %"}
          >
            <MenuItem key={0.05} value={0.05}>
              <Typography fontWeight={"bold"}>{"5%"}</Typography>
            </MenuItem>
            <MenuItem key={0.1} value={0.1}>
              <Typography fontWeight={"bold"}>{"10%"}</Typography>
            </MenuItem>
            <MenuItem key={0.15} value={0.15}>
              <Typography fontWeight={"bold"}>{"15%"}</Typography>
            </MenuItem>
            <MenuItem key={0.2} value={0.2}>
              <Typography fontWeight={"bold"}>{"20%"}</Typography>
            </MenuItem>{" "}
            <MenuItem key={0.25} value={0.25}>
              <Typography fontWeight={"bold"}>{"25%"}</Typography>
            </MenuItem>{" "}
            <MenuItem key={0.3} value={0.3}>
              <Typography fontWeight={"bold"}>{"30%"}</Typography>
            </MenuItem>{" "}
            <MenuItem key={0.5} value={0.5}>
              <Typography fontWeight={"bold"}>{"50%"}</Typography>
            </MenuItem>{" "}
            <MenuItem key={0.7} value={0.7}>
              <Typography fontWeight={"bold"}>{"70%"}</Typography>
            </MenuItem>
            <MenuItem key={1} value={1}>
              <Typography fontWeight={"bold"}>{"100%"}</Typography>
            </MenuItem>{" "}
          </Select>
        </FormControl>
      </Stack>
      {/* Line 5 */}
      <Stack direction={"row"} justifyContent="space-between" spacing={1}>
        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            type="number"
            sx={{
              backgroundColor:
                (amountCalculation === "st" || amountCalculation === "mb") &&
                grey[200],
            }}
            disabled={amountCalculation === "st" || amountCalculation === "mb"}
            value={stats.riskOfEquity}
            onChange={(e) => onStatsChange(e, "riskOfEquity")}
            label={"RISK OF EQUITY %"}
          />
        </FormControl>
        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            disabled
            sx={{ backgroundColor: grey[200] }}
            type="number"
            value={stats.marginBalance}
            label={"MARGIN BALANCE"}
          />
        </FormControl>
      </Stack>
      {/* Line 6 */}
      <Stack direction={"row"} justifyContent="space-between" spacing={1}>
        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel id="symbolLabel">MARGIN POSITION</InputLabel>
          <Select
            MenuProps={{ disableScrollLock: true }}
            value={stats.marginPosition}
            onChange={(e) => onStatsChange(e, "marginPosition")}
            label={"MARGIN POSITION"}
          >
            <MenuItem key={"CROSSED"} value={"CROSSED"}>
              <Typography fontWeight={"bold"}>{"CROSSED"}</Typography>
            </MenuItem>
            <MenuItem key={"ISOLATED"} value={"ISOLATED"}>
              <Typography fontWeight={"bold"}>{"ISOLATED"}</Typography>
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ flexGrow: 1 }}>
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 1, max: 125 } }}
            onChange={(e) => onStatsChange(e, "leverage")}
            value={stats.leverage}
            label={"LEVERAGE(1-125)"}
          />
        </FormControl>
      </Stack>
      <Divider sx={{ mb: 3 }} />
      <Stack direction={"row"} justifyContent="space-between" spacing={1}>
        <Button
          sx={{ flexGrow: 1, py: 2, backgroundColor: green[500] }}
          variant="contained"
        >
          BUY/LONG
        </Button>
        <Button
          sx={{ flexGrow: 1, py: 2, backgroundColor: red[400] }}
          variant="contained"
        >
          SELL/SHORT
        </Button>
      </Stack>
    </Box>
  );
};

export default ManualOrderTab;
