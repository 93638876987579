import { Outlet } from "react-router-dom";
import UnAuthHeader from "../component/header/UnAuthedHeader";

const HeaderFooterLayout = () => (
  <>
    <UnAuthHeader />
    <Outlet />
  </>
);

export default HeaderFooterLayout;
