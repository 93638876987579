import { Button, Divider, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Box, Stack } from "@mui/system";
import React from "react";
import EmailEdit from "../../component/header/support/Security/EmailEdit";
import PasswordEdit from "../../component/header/support/Security/PasswordEdit";
import SupportCard from "../../component/support/SupportCard";
import useLanguage from "./../../hook/useLanguage";
import GppGoodSharpIcon from "@mui/icons-material/GppGoodSharp";

const Security = () => {
  const { direction } = useLanguage();
  return (
    <SupportCard title="Authentication">
      <EmailEdit />
      <Divider sx={{ borderStyle: "dashed" }} />
      <PasswordEdit />
      <Divider sx={{ borderStyle: "dashed" }} />
      <Box
        sx={{
          borderStyle: "dotted",
          borderColor: blue[500],
          borderWidth: "1px",
          borderRadius: 2,
          bgcolor: blue[50],
          p: "12px",
          mt: "20px",
        }}
      >
        <Stack direction={direction} alignItems="center" spacing={1}>
          <Box height={"80px"} pt="10px" width={"50px"} color={blue[400]}>
            <GppGoodSharpIcon />
          </Box>
          <Box color="black">
            <Typography fontWeight={"bold"}>Secure Your Account</Typography>
            <Typography>
              Two-factor authentication adds an extra layer of security to your
              account. To log in, in addition you'll need to provide a 6 digit
              code.
            </Typography>
          </Box>
          <Button variant="contained" sx={{ px: "30px" }}>
            Enable
          </Button>
        </Stack>
      </Box>
    </SupportCard>
  );
};

export default Security;
