import { CircularProgress, Modal } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";

const CoverScreenLoader = ({ isLoading }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    p: 4,
  };

  return (
    <Modal open={isLoading}>
      <Box sx={style}>
        <CircularProgress size={100} />
      </Box>
    </Modal>
  );
};

export default CoverScreenLoader;
