import * as APIHelper from "./APIHelper";

const apiName = "mudawylAccountProfileRESTv1";

export const getUserProfile = async () => {
  return await APIHelper.getAuthedData("/v1/priv/account/profile", apiName);
};

export const putUserProfile = async (body) => {
  return await APIHelper.putAuthedData(
    "/v1/priv/account/profile",
    body,
    apiName
  );
};
