import { TextField, Select, Button, MenuItem } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import SupportCard from "./../../component/support/SupportCard";
import useLanguage from "./../../hook/useLanguage";
import useColorMode from "./../../hook/useColorMode";
import ProfileRow from "../../component/support/Profile/ProfileRow";
import APIFunction from "../../api";
import moment from "moment-timezone";
import { countries } from "countries-list";
import CoverScreenLoader from "../../component/CoverScreenLoader";

const Profile = () => {
  const [profile, setProfile] = useState({
    lastName: "",
    firstName: "",
    country: "",
    language: "",
    timezone: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const getProfile = () => {
    setIsLoading(true);
    APIFunction.getUserProfile()
      .then((res) => setProfile(res))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handleDiscard = () => {
    getProfile();
  };

  const handleSubmit = () => {
    setIsLoading(true);
    APIFunction.putUserProfile(profile)
      .then((res) => setProfile(res))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleProfileChange = (e) => {
    setProfile((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const { direction } = useLanguage();
  const { mode } = useColorMode();
  const inputBgColor = mode === "light" ? grey[100] : null;
  const inputBgStyle = { flexGrow: 1, bgcolor: inputBgColor };
  const buttonAlign = {
    ml: direction === "row" ? "auto" : null,
    mr: direction === "row" ? null : "auto",
  };
  return (
    <>
      <CoverScreenLoader isLoading={isLoading} />
      <SupportCard title={"Profile Details"}>
        <ProfileRow id="Full Name" title={"Full Name"}>
          <TextField
            size="small"
            name="firstName"
            value={profile.firstName}
            onChange={handleProfileChange}
            sx={inputBgStyle}
          />
          <TextField
            size="small"
            name="lastName"
            value={profile.lastName}
            onChange={handleProfileChange}
            sx={inputBgStyle}
          />
        </ProfileRow>
        <ProfileRow id={"Country"} title={"Country"}>
          <Select
            size="small"
            sx={inputBgStyle}
            name="country"
            value={profile.country}
            onChange={handleProfileChange}
          >
            {Object.keys(countries).map((countryCode) => (
              <MenuItem key={countryCode} value={countryCode}>
                {countries[countryCode].native}
              </MenuItem>
            ))}
          </Select>
        </ProfileRow>
        <ProfileRow id={"Language"} title={"Language"}>
          <Select
            size="small"
            sx={inputBgStyle}
            name="language"
            value={profile.language}
            onChange={handleProfileChange}
          >
            <MenuItem key={"EN"} value={"EN"}>
              English
            </MenuItem>
            <MenuItem key={"AR"} value={"AR"}>
              Arabic
            </MenuItem>
          </Select>
        </ProfileRow>
        <ProfileRow id={"Timezone"} title={"Timezone"}>
          <Select
            size="small"
            sx={inputBgStyle}
            name="timezone"
            value={profile.timezone}
            onChange={handleProfileChange}
          >
            {moment.tz.names().map((zone) => (
              <MenuItem key={zone} value={zone}>
                {zone}
              </MenuItem>
            ))}
          </Select>
        </ProfileRow>
        <Stack direction={direction} sx={{ mt: 2 }} spacing={1}>
          <Button
            sx={buttonAlign}
            variant="contained"
            color="inactive"
            onClick={handleDiscard}
          >
            Discard
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Saves Changes
          </Button>
        </Stack>
      </SupportCard>
    </>
  );
};

export default Profile;
