import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { useState } from "react";
import ManualOrderTab from './ManualPanelTabs/ManualOrderTab';

const ManualPanel = ({ currentKey }) => {
  const [tab, setTab] = useState("1");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ width: '90%',mx:"5%", typography: 'body1' }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab sx={{width:"50%",fontWeight:"bold"}} label="ORDER" value="1" />
            <Tab  sx={{width:"50%",fontWeight:"bold"}} label="TAKE PROFIT" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1"><ManualOrderTab/></TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
      </TabContext>
    </Box>
  );
};

export default ManualPanel;
