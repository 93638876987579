import * as APIHelper from "./APIHelper";

const apiName = "mudawylAccountExchangeRESTv1";

export const listWhitelistIPAddress = async () => {
  return await APIHelper.getAuthedData(
    "/v1/priv/account/exchange/whitelist/ip",
    apiName
  );
};

export const getExchangeTotalBalance = async () => {
  return await APIHelper.getAuthedData(
    "/v1/priv/account/exchange/balance",
    apiName
  );
};
