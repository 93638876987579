import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider, ListItemText, Menu, MenuItem } from "@mui/material";
import useLanguage from "./../../hook/useLanguage";
import FlagIcon from "../FlagIcon";
import { blue } from "@mui/material/colors";
import useAuth from "../../hook/useAuth";
import AccountMenu from "./AccountMenu";

const HeaderMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { signOut } = useAuth();
  // const [accounntAnchorEL, setAccountAnchorEI] = useState(null);
  const { language, toggleLanguage, t, direction } = useLanguage();
  const open = Boolean(anchorEl);
  // const accountOpen=Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuIcon
        id={"headermenu-icon"}
        aria-controls={open ? "header-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      />
      <Menu
        id="basic-menu"
        disableScrollLock={true}
        sx={{ mt: 6 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "headermenu-icon",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: direction === "row" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: direction === "row" ? "right" : "left",
        }}
      >
        <AccountMenu handleHeaderClose={handleClose} />
        <MenuItem sx={{ ...menuItemHover }} onClick={handleClose}>
          {t("Twitter")}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ ...menuItemHover }}
          onClick={() => {
            handleClose();
            toggleLanguage();
          }}
        >
          <ListItemText>{t("Language")}</ListItemText>
          <FlagIcon language={language} sx={{ ml: "5px" }} />
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ ...menuItemHover }}
          onClick={() => {
            signOut();
            handleClose();
          }}
        >
          {t("Sign Out")}
        </MenuItem>
      </Menu>
    </>
  );
};

const menuItemHover = { "&:hover": { color: blue[200] } };

export default HeaderMenu;
