import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import useAuth from "../../../../hook/useAuth";
import useLanguage from "./../../../../hook/useLanguage";
import SecurityTitledTextField from "./SecurityTitledTextField";

const EmailInput = ({ toggle }) => {
  const { direction } = useLanguage();
  const { user, updateEmail } = useAuth();
  const [email, setEmail] = useState(user?.email);
  return (
    <>
      <Stack direction={direction} py={1} spacing={2}>
        <SecurityTitledTextField
          sx={{ flexGrow: 1 }}
          title={"Enter New Email Address"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {/* <SecurityTitledTextField
          sx={{ flexGrow: 1 }}
          title={"Confirm Password"}
          value={""}
        /> */}
      </Stack>
      <Stack direction={direction} py={1} spacing={2}>
        <Button variant="contained" onClick={() => updateEmail(email)}>
          {" "}
          Update Email
        </Button>
        <Button onClick={toggle} sx={{ color: grey[400] }}>
          {" "}
          Cancel
        </Button>
      </Stack>
    </>
  );
};

export default EmailInput;
