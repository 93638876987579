import * as APIHelper from "./APIHelper";

const apiName = "mudawylAccountNotificationRESTv1";

export const getAlertMethod = async () => {
  return await APIHelper.getAuthedData(
    "/v1/priv/account/notification/method",
    apiName
  );
};

export const getNotificationSettings = async () => {
  return await APIHelper.getAuthedData(
    "/v1/priv/account/notification",
    apiName
  );
};

export const updateNotificationSettings = async (body) => {
  return await APIHelper.putAuthedData(
    `/v1/priv/account/notification`,
    body,
    apiName
  );
};
