import * as APIHelper from "./APIHelper";

export const cancelFuturePosition = async (apiKey) => {
  return await APIHelper.deleteAuthedData(
    `/v1/priv/exchange/position/${apiKey}`,
    {
      symbol: "BTC/USDT",
    },
    "mudawylExchangeRESTv1"
  );
};
