import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { useLocation, useNavigate } from "react-router-dom";
const SupportSidebar = ({ pages }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const SupportButtons = (label, link) => (
    <ListItemButton
      sx={{
        "&:hover .MuiListItemIcon-root": {
          color: "primary.main",
        },
      }}
      key={label}
      onClick={() => navigate(`/support/${link}`)}
    >
      <ListItem>
        <ListItemIcon
          sx={{
            color: `/support/${link}` === pathname ? "primary.main" : null,
          }}
        >
          <RemoveIcon />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    </ListItemButton>
  );

  return (
    <Paper>{pages.map(({ label, link }) => SupportButtons(label, link))}</Paper>
  );
};

export default SupportSidebar;
