import React, { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Menu, MenuItem, Box } from "@mui/material";
import useLanguage from "./../../hook/useLanguage";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import useColorMode from "../../hook/useColorMode";

const AccountMenu = ({ handleHeaderClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, direction } = useLanguage();
  const {mode}=useColorMode();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (supportLink) => {
    handleHeaderClose();
    navigate(`/support/${supportLink}`);
    setAnchorEl(null);
  };

  const handleClose=()=>{
    setAnchorEl(null)
  }

  return (
    <>
      <MenuItem
        sx={{ minWidth: "200px", ...menuItemHover }}
        onClick={handleClick}
      >
        <Box
          sx={{ color: mode==="light"?"black":"white", display: "flex", justifyContent: "center" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          Account
        </Box>
        <ChevronRightIcon sx={{ ml: "auto" }} />
      </MenuItem>
      <Menu
        id="account-menu"
        disableScrollLock={true}
        sx={{ mt: 6 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "headermenu-icon",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: direction === "row" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: direction === "row" ? "right" : "left",
        }}
      >
        <MenuItem
          sx={{ ...menuItemHover }}
          onClick={() => handleSelect("profile")}
        >
          {t("Profile")}
        </MenuItem>
        <MenuItem
          sx={{ ...menuItemHover }}
          onClick={() => handleSelect("security")}
        >
          {t("Security")}
        </MenuItem>
        <MenuItem
          sx={{ ...menuItemHover }}
          onClick={() => handleSelect("analysis")}
        >
          {t("Analysis")}
        </MenuItem>
        <MenuItem
          sx={{ ...menuItemHover }}
          onClick={() => handleSelect("exchange")}
        >
          {t("Exchange")}
        </MenuItem>
        <MenuItem
          sx={{ ...menuItemHover }}
          onClick={() => handleSelect("notification")}
        >
          {t("Notification")}
        </MenuItem>
        <MenuItem
          sx={{ ...menuItemHover }}
          onClick={() => handleSelect("subscription")}
        >
          {t("Subscription")}
        </MenuItem>
      </Menu>
    </>
  );
};

const menuItemHover = { "&:hover": { color: blue[200] } };

export default AccountMenu;
