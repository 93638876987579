import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import useLanguage from "./../../hook/useLanguage";
import useColorMode from "./../../hook/useColorMode";

const OrderTable = ({ history = false,columnNames,children }) => {
  const { t, direction } = useLanguage();
  const { mode } = useColorMode();

  let finalColumns = columnNames;

  // const rows = [
  //   {
  //     id: "1",
  //     time: "2022-08-15 14:47:36",
  //     symbol: "BTCUSDT",
  //     type: "LIMIT",
  //     side: "buy",
  //     price: "20,000.00 USDT",
  //     size: "1.000BTC",
  //     filled: "0.000BTC",
  //     TP: "-",
  //     SL: "-",
  //   },
  // ];

  if (direction === "row-reverse") finalColumns = columnNames.reverse();

  return (
    <>
      <Stack flexDirection={direction}>
        {history && (
          <Box>
            <Typography
              textAlign={direction === "row-reverse" ? "right" : null}
              fontWeight={"700"}
            >
              {t("ORDERS")}
            </Typography>
            <Typography
              variant="caption"
              fontWeight={"600"}
              sx={{ color: grey[500] }}
            >
              {`${t("Updated")} ${15} ${t("seconds ago")}`}
            </Typography>
          </Box>
        )}
      </Stack>
      <TableContainer sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow
              sx={{ backgroundColor: mode === "light" ? grey[50] : grey[700] }}
            >
              {finalColumns.map((label) => (
                <TableCell
                  align={direction === "row" ? "left" : "right"}
                  key={label}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {rows.map(
              ({
                id,
                time,
                symbol,
                type,
                side,
                price,
                size,
                filled,
                TP,
                SL,
              }) => {
                let action = (
                  <Button sx={{ color: grey[500] }}>{t("CANCEL")}</Button>
                );
                const row = [
                  time,
                  symbol,
                  type,
                  side,
                  price,
                  size,
                  filled,
                  TP,
                  SL,
                  action,
                ];
                let finalRow = row;
                if (direction === "row-reverse") finalRow = row.reverse();

                return (
                  <TableRow key={id}>
                    {finalRow.map((value, index) => (
                      <TableCell
                        align={direction === "row" ? "left" : "right"}
                        key={index}
                      >
                        {value}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              }
            )} */}
            {children?children:<TableRow></TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderTable;
