import { Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import useLanguage from "./../../../../hook/useLanguage";
import SecurityTitledTextField from "./SecurityTitledTextField";
import useAuth from "./../../../../hook/useAuth";

const PasswordInput = ({ toggle }) => {
  const { direction } = useLanguage();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { changePassword } = useAuth();

  const handleUpdate = () => {
    if (newPassword !== confirmPassword) {
      setError("two new password does not match");
    } else {
      changePassword(oldPassword, newPassword).catch((err) => {
        setError(err.message);
      });
    }
  };

  return (
    <>
      <Stack direction={direction} py={1} spacing={2}>
        <SecurityTitledTextField
          sx={{ flexGrow: 1 }}
          title={"Current Password"}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <SecurityTitledTextField
          sx={{ flexGrow: 1 }}
          title={"New Password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <SecurityTitledTextField
          sx={{ flexGrow: 1 }}
          title={"Confirm New Password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Stack>
      <Typography color={"error"}>{error}</Typography>
      <Stack direction={direction} py={1} spacing={2}>
        <Button variant="contained" onClick={handleUpdate}>
          {" "}
          Update Password
        </Button>
        <Button onClick={toggle} sx={{ color: grey[400] }}>
          {" "}
          Cancel
        </Button>
      </Stack>
    </>
  );
};

export default PasswordInput;
