import { Box, Typography } from "@mui/material";

import { grey } from "@mui/material/colors";
import React from "react";
import CoverLine from "./CoverLine";

const PlanCoverage = ({ activePlan }) => {
  return (
    <Box sx={{ p: "20px", backgroundColor: grey[200] }} borderRadius="5PX">
      {activePlan ? (
        <>
          <Typography variant="h6" mt="20px" mb={"40px"} fontWeight={"bold"}>
            {`What does ${activePlan.name} Plan Cover?`}
          </Typography>
          <CoverLine
            covered
            text={`Up to ${activePlan.maxApiKey} Exchange API Key`}
          />
          <CoverLine
            covered
            text={`Up to $${activePlan.maxBalanceUSD} Total Account Capital`}
          />
          <CoverLine covered text={`Manual Strategy`} />
          <CoverLine
            covered={activePlan.strategyAutomated}
            text={`Automated Strategy`}
          />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PlanCoverage;
