import * as APIHelper from "./APIHelper";

const apiName = "mudawylExchangeRESTv1";

export const listOpenOrder = async (keyId) => {
  return await APIHelper.getAuthedData(
    `/v1/priv/exchange/order/${keyId}/list?symbol=BTC/USDT`,
    apiName
  );
};

export const listOrderHistory = async (keyId) => {
  return await APIHelper.getAuthedData(
    `/v1/priv/exchange/order/${keyId}/history?symbol=BTC/USDT`,
    apiName
  );
};

export const listTradeHistory = async (keyId) => {
  return await APIHelper.getAuthedData(
    `/v1/priv/exchange/trade/${keyId}/history`,
    apiName
  );
};

export const getFuturesOpenPosition = async (keyId) => {
  return await APIHelper.getAuthedData(
    `/v1/priv/exchange/position/${keyId}?symbol=BTC/USDT`,
    apiName
  );
};
