import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import useAuth from "../../hook/useAuth";
import UserNameTag from "../usercard/UserNameTag";

const UserHeaderCard = () => {
  const { user } = useAuth();
  const { given_name, family_name, email, SubscriptionType } = user;
  return (
    <Box display="flex" m={2} alignItems="center">
      <Skeleton variant="rectangular" height={50} width={50} />
      <Box ml={1}>
        <UserNameTag
          name={`${given_name} ${family_name}`}
          type={SubscriptionType?.name}
        />
        <Typography>{email}</Typography>
      </Box>
    </Box>
  );
};

export default UserHeaderCard;
