import React, { useState,useEffect } from "react";
import APIFunction from "../../api";
import TradeLayout from "./../../Layout/TradeLayout";
import useLanguage from "./../../hook/useLanguage";
import ManualPanel from "../../component/trade/ManualPanel";

const ManualTrading = () => {
  const [exchangeKeys, setExchangeKeys] = useState([]);
  const [currentKey, setCurrentKey] = useState("");
  const { t, direction } = useLanguage();

  const getExchangeKeys = async () => {
    const { items: keys } = await APIFunction.listExchangeKey();
    const manualKeys = keys.filter((key) => key.strategyType === "MANUAL");

    setExchangeKeys(manualKeys);
    setCurrentKey(manualKeys[0] ? manualKeys[0].id : "");
  };
  useEffect(()=>{
    getExchangeKeys()
    
  },[])

  return (
    <TradeLayout
      exchangeKeys={exchangeKeys} 
      currentKey={currentKey}
      setCurrentKey={setCurrentKey}
      manual
    >
      <></>
      <ManualPanel currentKey={currentKey}/>
    </TradeLayout>
  );
};

export default ManualTrading;
