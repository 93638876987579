import * as APIHelper from "./APIHelper";

const apiName = "mudawylStrategyAutomatedRESTv1";

export const listExchangeKeyFollows = async (keyId) => {
  return await APIHelper.getAuthedData(
    `/v1/priv/strategy/follow/exchange/key/${keyId}`,
    apiName
  );
};

export const createExchangeKeyFollows = async (keyId, body) => {
  return await APIHelper.postAuthedData(
    `/v1/priv/strategy/follow/exchange/key/${keyId}`,
    body,
    apiName
  );
};

export const updateExchangeKeyFollow = async (strategyId, body) => {
  return await APIHelper.putAuthedData(
    `/v1/priv/strategy/follow/${strategyId}`,
    body,
    apiName
  );
};
