import * as accountExchange from "./accountExchange";
import * as accountExchangeKey from "./accountExchangeKey";
import * as accountNotification from "./accountNotification";
import * as accountProfile from "./accountProfile";
import * as accountSubscription from "./accountSubscription";
import * as accountSubscriptionStripe from "./accountSubscriptionStripe";
import * as exchangeOrder from "./exchangOrder";
import * as publicData from "./publicData";
import * as strategyAutomated from "./strategyAutomated";
import * as exchangeOrderExecute from "./exchangeOrderExecute";

const APIFunction = {
  ...accountExchange,
  ...accountExchangeKey,
  ...accountNotification,
  ...accountProfile,
  ...accountSubscription,
  ...accountSubscriptionStripe,
  ...exchangeOrder,
  ...exchangeOrderExecute,
  ...publicData,
  ...strategyAutomated,
};

export default APIFunction;
