import { Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box, Stack } from "@mui/system";
import React from "react";
import useLanguage from "./../../../../hook/useLanguage";

const InfoDisplay = ({ title, value, buttonText, toggle }) => {
  const { direction } = useLanguage();
  return (
    <Stack direction={direction} py={"15px"} justifyContent="space-between">
      <Box>
        <Typography fontSize={"14px"} fontWeight={"bold"}>
          {title}
        </Typography>
        <Typography fontSize={"12px"} color="gray">
          {value}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="inactive"
        sx={{ fontWeight: "600", color: grey[600] }}
        onClick={toggle}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};

export default InfoDisplay;
