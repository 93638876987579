import React from "react";
import { Box } from "@mui/system";
import { Outlet, useNavigate } from "react-router-dom";
import bg from "../assets/image/header-bg.png";
import logo from "../assets/image/logo.png";
import useColorMode from "./../hook/useColorMode";
import { Paper } from "@mui/material";
import { useEffect } from "react";

const AuthLayout = ({ user, userLoading }) => {
  const navigate = useNavigate();

  const { mode } = useColorMode();

  useEffect(() => {
    if (user.sub && !userLoading) {
      navigate("/trade/strategy");
    }
  }, [user, userLoading, navigate]);

  return (
    <>
      <Box
        display={mode === "dark" ? "none" : null}
        component={"img"}
        left={0}
        top={"-10%"}
        width="100%"
        minHeight={"35vh"}
        position="absolute"
        zIndex={-1}
        src={bg}
      ></Box>

      <Box display="flex" alignItems={"center"} flexDirection="column" py="3%">
        <Box component={"img"} width="7%" src={logo} />
        <Paper
          sx={{
            p: "3%",
            width: "30%",
            minWidth: "500px",
            mt: "10px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            "&>*": {
              my: "3px",
            },
            "& .MuiTypography-root, .MuiButtonBase-root": {
              fontWeight: "bold !important",
            },
          }}
        >
          <Outlet />
        </Paper>
      </Box>
    </>
  );
};

export default AuthLayout;
