import { Box, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import useColorMode from './../../../../hook/useColorMode';

const TitledTextField = ({ title, value, onChange,sx }) => {
  const {mode}=useColorMode();
  const inputBgColor = mode === "light" ? grey[100] : null;
  

  return (
    
    <Box sx={sx}>
      <Typography fontSize={"14px"} fontWeight={"bold"}>{title}</Typography>
      <TextField sx={{bgcolor:inputBgColor}} fullWidth size="small" value={value} onChange={onChange } />
    </Box>
  );
};

export default TitledTextField;
