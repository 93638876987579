import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import APIFunction from "../../api";
import CoverScreenLoader from "../CoverScreenLoader";
import StrategyHistory from "./strategyHistory";

const AutomatedPanel = ({
  currentKey,
  currentProfile,
  currentStrategy,
  setCurrentStrategy,
  setCurrentProfile,
  profileList,
  strategyList,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [followedId, setFollowedId] = useState(null);
  const [isEnabled, setIsEnabled] = useState(false);
  const [strategyHistory, setStrategyHistory] = useState([]);
  const [historyPeriod, setHistoryPeriod] = useState(30);

  const updateCurrentFollowStrategy = async (e) => {
    setIsLoading(true);
    await APIFunction.updateExchangeKeyFollow(followedId, {
      active: isEnabled,
      strategyAutomatedId: e.target.value,
      strategyRiskProfileId: currentProfile,
    });
    const { items: history } = await APIFunction.listStrategyHistory(
      currentProfile
    );
    setStrategyHistory(history);
    setIsLoading(false);
  };
  const updateCurrentFollowRisk = async (e) => {
    setIsLoading(true);
    await APIFunction.updateExchangeKeyFollow(followedId, {
      active: isEnabled,
      strategyAutomatedId: currentStrategy,
      strategyRiskProfileId: e.target.value,
    });
    setIsLoading(false);
  };

  const getProfileHistory = async (period) => {
    setIsLoading(true)
    try{
      const { items: history } = await APIFunction.listStrategyHistory(
        currentStrategy,period
      );
      setStrategyHistory(history);
    }catch(err){

    }
 
    setIsLoading(false)
  };


  useEffect(() => {
    if (currentKey) {
      const checkIfFollowed = (follows) => {
        follows.forEach((follow) => {
          const { StrategyRiskProfile, active } = follow;
          setIsEnabled(active);
          setFollowedId(follow.id);
          setCurrentProfile(StrategyRiskProfile.id);
        });
      };
      const getKeyFollows = async () => {
        const { items: follows } = await APIFunction.listExchangeKeyFollows(
          currentKey
        );
        checkIfFollowed(follows);
        setIsLoading(false);
      };
      getKeyFollows();
    }
  }, [currentKey, currentStrategy, setCurrentProfile]);

  useEffect(() => {
  

    if (currentStrategy) {
      getProfileHistory(historyPeriod);
    }
  }, [currentStrategy,historyPeriod]);

  const onToggle = async () => {
    setIsLoading(true);
    if (followedId) {
      await APIFunction.updateExchangeKeyFollow(followedId, {
        active: !isEnabled,
        strategyAutomatedId: currentStrategy,
        strategyRiskProfileId: currentProfile,
      });
    } else {
      await APIFunction.createExchangeKeyFollows(currentKey, {
        active: !isEnabled,
        strategyAutomatedId: currentStrategy,
        strategyRiskProfileId: currentProfile,
      });
    }
    setIsEnabled((prev) => !prev);
    setIsLoading(false);
  };

  return (
    <>
      <CoverScreenLoader isLoading={isLoading} />
      <Stack
        flexDirection={"row"}
        justifyContent="space-evenly"
        alignItems={"center"}
      >
        <Box>
          <Typography fontWeight={"bold"}>AUTO TRADING BOT</Typography>
          <Typography fontWeight={"bold"} fontSize={11} color={grey[300]}>
            Open position based on strategy trigger
          </Typography>
        </Box>
        <FormControlLabel
          sx={{ "& *": { fontWeight: "bold" } }}
          labelPlacement="start"
          label="ENABLED"
          control={
            <Switch checked={isEnabled} disabled={!currentKey} onChange={onToggle} color="primary" />
          }
        />
      </Stack>
      <Divider sx={{ my: 2 }} />
      <Grid my="30px" container>
        <Grid px={"5%"} item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="strategyLabel">STRATEGY NAME</InputLabel>
            <Select
              MenuProps={{ disableScrollLock: true }}
              labelId="strategyLabel"
              value={currentStrategy}
              label={"STRATEGY NAME"}
              disabled={!isEnabled}
              onChange={(e) => {
                updateCurrentFollowStrategy(e);
                console.log(e.target.value)
                setCurrentStrategy(e.target.value);
              }}
            >
              {strategyList.map((strategy) => (
                <MenuItem key={strategy.id} value={strategy.id}>
                  <Typography fontWeight={"bold"}>{strategy.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid px={"5%"} item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="symbolLabel">SYMBOL</InputLabel>
            <Select
              MenuProps={{ disableScrollLock: true }}
              labelId="symbolLabel"
              value={"BTCUSDT"}
              disabled={!isEnabled}
              label={"SYMBOL"}
            >
              <MenuItem key={"BTCUSDT"} value={"BTCUSDT"}>
                <Typography fontWeight={"bold"}>{"BTCUSDT"}</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid px={"5%"} pt="30px" item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="porfileLabel">Risk OF EQUITY PROFILE</InputLabel>
            <Select
              MenuProps={{ disableScrollLock: true }}
              labelId="porfileLabel"
              value={currentProfile}
              disabled={!isEnabled}
              label={"Risk OF EQUITY PROFILE"}
              onChange={(e) => {
                updateCurrentFollowRisk(e);
                setCurrentProfile(e.target.value);
              }}
            >
              {profileList.map((profile) => (
                <MenuItem key={profile.id} value={profile.id}>
                  <Typography fontWeight={"bold"}>{profile.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid px={"5%"} pt="30px" item xs={12} sm={6}>
          <FormControl disabled fullWidth>
            <InputLabel id="riskOfEquityLabel">RISK OF EQUITY %</InputLabel>
            <Select
              labelId="riskOfEquityLabel"
              label={"RISK OF EQUITY %"}
              value="default"
            >
              <MenuItem value="default">
                <Typography fontWeight={"bold"}>{`${
                  profileList.filter(
                    (profile) => profile.id === currentProfile
                  )[0]?.percentage
                }%`}</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid px={"5%"} pt="30px" item xs={12}>
          {strategyHistory.length ? (
            <StrategyHistory
              history={strategyHistory}
              historyPeriod={historyPeriod}
              setHistoryPeriod={setHistoryPeriod}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AutomatedPanel;
