import { grey, blue, blueGrey } from "@mui/material/colors";

const commonTheme = {
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: "secondary.main",
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
};

const lightPalette = {
  // palette values for light mode
  primary: { main: blue[500] },
  inactive: { main: grey[300] },
  text: {
    secondary: grey[800],
  },
  slate: {
    contrastText: "#ffffff",
  },
};

const darkPalette = {
  // palette values for dark mode
  primary: { main: blue[500] },
  inactive: { main: grey[300] },

  background: {
    default: blueGrey[900],
    paper: "#263238",
  },
  text: {
    primary: "#fff",
    secondary: grey[500],
  },
  slate: {
    contrastText: "#ffffff",
  },
};
const getCurrentTheme = (mode) => ({
  palette: {
    mode,
    ...(mode === "light" ? lightPalette : darkPalette),
  },
  ...commonTheme,
});

export default getCurrentTheme;
