import React, { useState, useEffect } from "react";
import SupportCard from "../../component/support/SupportCard";
import {
  Stack,
  Box,
  Typography,
  Grid,
  LinearProgress,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Link,
  Skeleton,
} from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import useLanguage from "./../../hook/useLanguage";
import { blue, grey, orange } from "@mui/material/colors";
import APIFunction from "../../api";
import moment from "moment";
import CoverScreenLoader from "./../../component/CoverScreenLoader";
import SubscribeModal from "../../component/general/modal/subscribeModal/SubscribeModal";
import ConfirmModal from "../../component/general/modal/ConfirmModal";

const Subscription = () => {
  const { direction } = useLanguage();
  const [accountInfo, setAccountInfo] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { maxBalanceUSD, balance, stripe, monthlyPrice, name, history } =
    accountInfo;
  const [isLoading, setIsLoading] = useState(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const onCancelSubscription = async () => {
    setIsLoading(true);
    await APIFunction.updateStripeSubscription({
      cancelAtPeriodEnd: true,
    });
    getSubscriptionInfo();
    setIsLoading(false);
    setConfirmModalOpen(false);
  };

  const getSubscriptionInfo = async () => {
    try {
      let APICalls = await Promise.all([
        APIFunction.getUserSubscription(),
        APIFunction.getExchangeTotalBalance(),
        APIFunction.listSubscriptionsPlans(),
        APIFunction.getStripeSubscription(),
        APIFunction.listStripeBillingHistory(),
      ]);

      const { SubscriptionType } = APICalls[0];
      const { USD: balance } = APICalls[1];
      const { items: plans } = APICalls[2];
      const stripe = APICalls[3];
      const stripeHistory = APICalls[4];
      const monthlyPrice = plans.filter(
        (plan) => plan.name === SubscriptionType.name
      )[0]?.monthlyPriceUSD;

      setAccountInfo({
        ...SubscriptionType,
        balance,
        monthlyPrice: monthlyPrice ? monthlyPrice : 0,
        stripe,
        history: stripeHistory,
      });
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  return (
    <>
      <CoverScreenLoader isLoading={isLoading} />
      {!isLoading ? (
        <>
          <ConfirmModal
            open={confirmModalOpen}
            setOpen={setConfirmModalOpen}
            onSubmit={onCancelSubscription}
            confirmText={"Are You Sure You Want to Cancel this Subscription?"}
          />
          <SubscribeModal
            updateFunction={getSubscriptionInfo}
            open={isOpen}
            setOpen={setIsOpen}
          />
          <SupportCard title="Subscription">
            {maxBalanceUSD - balance < 500 && (
              <Box
                sx={{
                  borderStyle: "dotted",
                  borderColor: orange[500],
                  borderWidth: "1px",
                  borderRadius: 2,
                  bgcolor: orange[50],
                  p: "12px",
                  mt: "20px",
                }}
              >
                <Stack direction={direction} alignItems="center" spacing={1}>
                  <Box
                    height={"80px"}
                    pt="10px"
                    width={"50px"}
                    color={orange[400]}
                  >
                    <ReportIcon />
                  </Box>
                  <Box color="black">
                    <Typography fontWeight={"bold"}>
                      We need your attention!
                    </Typography>
                    <Typography>
                      Your total account capital is reaching the max limit.
                      Please upgrade your subscription to avoid any
                      interruption.
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            )}

            <Grid container mt={3} spacing={2}>
              <Grid item xs={12} md={7}>
                {name !== "DEFAULT" && (
                  <>
                    <Typography mb={1} fontWeight="bold">
                      {`Active until ${moment(
                        new Date(stripe?.currentPeriodEnd)
                      ).format("MMMM Do, YYYY")}`}
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      fontSize={14}
                      color={grey[500]}
                    >
                      {`Your subscriptions will${
                        Boolean(stripe?.cancelAtPeriodEnd)?"not":""
                      } automatically be renewed.`}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack direction={direction}>
                  {" "}
                  <Typography fontWeight={"bold"}>
                    Max Account Capital
                  </Typography>
                  <Typography
                    fontWeight={"bold"}
                    sx={{
                      ml: direction === "row" ? "auto" : null,
                      mr: direction !== "row" ? "auto" : null,
                    }}
                  >
                    ${maxBalanceUSD}
                  </Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  sx={{ my: 1 }}
                  value={(balance / maxBalanceUSD) * 100}
                />

                <Typography fontWeight="bold" fontSize={14} color={grey[500]}>
                  {`$${(maxBalanceUSD - balance).toFixed(2)} remaining`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography>
                  <Typography variant="span" fontWeight={"bold"}>
                    ${`${monthlyPrice}`}
                  </Typography>{" "}
                  Per Month
                </Typography>
                <Typography fontWeight="bold" fontSize={14} color={grey[500]}>
                  Upgrade to Advanced to increase total account capital.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack
                  direction={direction}
                  justifyContent="flex-end"
                  sx={{ mt: 2 }}
                  spacing={1}
                >
                  {(name !== "DEFAULT"&&!Boolean(stripe?.cancelAtPeriodEnd)) && (
                    <Button
                      variant="contained"
                      onClick={() => setConfirmModalOpen(true)}
                      color="inactive"
                    >
                      Cancel Subscription
                    </Button>
                  )}

                  <Button variant="contained" onClick={() => setIsOpen(true)}>
                    Upgrade Plan
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </SupportCard>
          <SupportCard sx={{ mt: 3 }} title="Billing History">
            <TableContainer sx={{ my: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={"bold"} fontSize={"1rem"}>
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"} fontSize={"1rem"}>
                        Description{" "}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"} fontSize={"1rem"}>
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={"bold"} fontSize={"1rem"}>
                        Invoice
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history?.map((record) => (
                    <TableRow key={record.number}>
                      <TableCell>{`${moment(
                        new Date(record.periodStart)
                      ).format("MMMM DD, YYYY")}`}</TableCell>
                      <TableCell>
                        <Typography color={blue[400]}>
                          {record.number}
                        </Typography>
                      </TableCell>
                      <TableCell>{`$${record.amountPaid / 100}`}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{ fontWeight: "bold" }}
                          color="inactive"
                          component={Link}
                          href={record.pdf}
                        >
                          Download
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SupportCard>
        </>
      ) : (
        <>
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", backgroundColor: "grey.200", height: "30vh" }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%",mt:"20px", backgroundColor: "grey.200", height: "50vh" }}
          />
        </>
      )}
    </>
  );
};

export default Subscription;
