import "./App.css";
import LanguageContextProvider from "./context/LanguageContextProvider";
import ColorModeContextProvider from "./context/ColorModeContextProvider";
import Router from "./Router";
import { CssBaseline } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./awsexports";
import UserContextProvider from "./context/UserContextProvider";

const custom_header = async () => {
  return {
    Authorization: `Bearer ${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  };
  // Alternatively, with Cognito User Pools use this:
  // return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
  // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
};

Amplify.configure({
  ...awsconfig,
  API: {
    endpoints: [
      [
        {
          name: "mudawylAccountProfileRESTv1",
          endpoint:
            "https://o9i2et99j4.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
          custom_header,
        },
        {
          name: "mudawylAccountExchangeRESTv1",
          endpoint:
            "https://luxu7exjpg.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
          custom_header,
        },
        {
          name: "mudawylPublicExchangeRESTv1",
          endpoint:
            "https://6qpetwrt3a.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
        },
        {
          name: "mudawylPublicSubscriptionRESTv1",
          endpoint:
            "https://vgatgfrpt7.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
          custom_header,
        },
        {
          name: "mudawylAccountNotificationRESTv1",
          endpoint:
            "https://hg0o55el2d.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
          custom_header,
        },
        {
          name: "mudawylAccountSubscriptionRESTv1",
          endpoint:
            "https://wmukyiyzu9.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
          custom_header,
        },
        {
          name: "mudawylStrategyAutomatedRESTv1",
          endpoint:
            "https://0947rdbwzi.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
          custom_header,
        },
        {
          name: "mudawylPublicStrategyRESTv1",
          endpoint:
            "https://564c5q6817.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
        },
        {
          name: "mudawylExchangeRESTv1",
          endpoint:
            "https://1has9c3ygi.execute-api.ca-central-1.amazonaws.com/staging",
          region: "ca-central-1",
          custom_header,
        },
      ],
    ],
  },
});

function App() {
  return (
    <UserContextProvider>
      <LanguageContextProvider>
        <ColorModeContextProvider>
          <div className="App">
            <CssBaseline />
            <Router />
          </div>
        </ColorModeContextProvider>
      </LanguageContextProvider>
    </UserContextProvider>
  );
}

export default App;
