/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "mudawylAccountProfileRESTv1",
            "endpoint": "https://o9i2et99j4.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        },
        {
            "name": "mudawylAccountExchangeRESTv1",
            "endpoint": "https://luxu7exjpg.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        },
        {
            "name": "mudawylPublicExchangeRESTv1",
            "endpoint": "https://6qpetwrt3a.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        },
        {
            "name": "mudawylPublicSubscriptionRESTv1",
            "endpoint": "https://vgatgfrpt7.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        },
        {
            "name": "mudawylAccountNotificationRESTv1",
            "endpoint": "https://hg0o55el2d.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        },
        {
            "name": "mudawylAccountSubscriptionRESTv1",
            "endpoint": "https://wmukyiyzu9.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        },
        {
            "name": "mudawylStrategyAutomatedRESTv1",
            "endpoint": "https://0947rdbwzi.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        },
        {
            "name": "mudawylPublicStrategyRESTv1",
            "endpoint": "https://564c5q6817.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        },
        {
            "name": "mudawylExchangeRESTv1",
            "endpoint": "https://1has9c3ygi.execute-api.ca-central-1.amazonaws.com/staging",
            "region": "ca-central-1"
        }
    ],
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_qCMoXoP1s",
    "aws_user_pools_web_client_id": "2ul9rgt7a2636inm7mgu394n5f",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;