import React, { useState } from "react";
import InfoDisplay from "./InfoDisplay";
import EmailInput from "./EmailInput";
import useAuth from "../../../../hook/useAuth";

const EmailEdit = () => {
  const [isEditting, setIsEditting] = useState(false);
  const { user } = useAuth();

  const toggleIsEditting = () => {
    setIsEditting((prev) => !prev);
  };

  return (
    <>
      {!isEditting ? (
        <InfoDisplay
          title="Email"
          value={user?.email}
          buttonText={"Change Email"}
          toggle={toggleIsEditting}
        />
      ) : (
        <EmailInput toggle={toggleIsEditting} />
      )}
    </>
  );
};

export default EmailEdit;
