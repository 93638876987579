import { Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import TitledTextField from "../../component/general/input/TitledTextField";
import { useNavigate } from "react-router-dom";
import useAuth from "./../../hook/useAuth";

const SignUp = () => {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const [info, setInfo] = useState({
    given_name: "",
    family_name: "",
    email: "",
    password: "",
  });
  const handleInfoChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = () => {
    signUp(info).then(() => navigate("/signin"));
  };

  return (
    <>
      <Typography variant="h5">Create an Account</Typography>
      <Stack flexDirection={"row"} alignItems="center" mb="30px">
        <Typography
          sx={{
            color: grey[400],
          }}
        >
          Already have an account?
        </Typography>
        <Button onClick={() => navigate("/signin")}>Sign in here</Button>
      </Stack>
      <Stack width={"80%"} flexDirection={"row"} alignItems="center" my="15px">
        <TitledTextField
          name="given_name"
          sx={{ my: "15x", mr: "2px" }}
          title="First Name"
          onChange={handleInfoChange}
        />
        <TitledTextField
          name="family_name"
          sx={{ my: "15x", ml: "2px" }}
          title="Last Name"
          onChange={handleInfoChange}
        />
      </Stack>
      <TitledTextField
        sx={{ width: "80%", my: "15x" }}
        name="email"
        onChange={handleInfoChange}
        title="Email"
      />
      <TitledTextField
        sx={{ width: "80%", my: "15px" }}
        title="Password"
        name="password"
        onChange={handleInfoChange}
        password
      />
      <Button
        variant="contained"
        sx={{ width: "250px", mt: "50px" }}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </>
  );
};

export default SignUp;
